import {toast} from "react-toastify";

export const handleApiError = (e: Error | any) => {
    if(e?.code === 429) {
        toast( 'Rate Limit Reached! Pelase Wait', {
            type: 'error'
        });
        return;
    }
    if(e?.message !== undefined && (e.message instanceof Array || e.message instanceof Object)) {
        if(e.message instanceof Array) {
            e.message.map((error: any) => handleApiError(error))
        } else {
            for (const [key, value] of Object.entries(e.message)) {
                handleApiError(value);
            }
        }
    } else if((e instanceof Array || e instanceof Object) && !e?.message) {
        e.map((error: any) => handleApiError(error))
    } else {
        toast(e?.message || e || 'Something Went Wrong!', {
            type: 'error'
        })
    }
}

export const handleJsonApiErrors = (errors: any) => {
    errors?.forEach(handleJsonApiError)
}

export const handleJsonApiError = (error: any) => {
    toast(error.detail || error.title || 'Something Went Wrong', {
        type: toast.TYPE.ERROR,
    })
}
