import React from 'react';
import "./styles.scss"

const Terms = () => {
    return (
        <div className="faqs container">
            <h1 className="text-center faqs__heading">Terms and Conditions</h1>

            <ol>
                <li>The Seller shall sell to the Buyer the right to a certain number of Cryptocurrency Units and, upon redemption of said right, transfer the sole authority to dispose thereof, and the Buyer shall acquire from the Seller the right to a certain number of Cryptocurrency Units and, upon transfer, take ownership thereof or acquire the sole authority to dispose thereof, as described below.</li>
                <li>Core tokens are utility tokens that can be used by the holder to process payments or for all core services offered in the core system.</li>
                <li>In order to redeem the right to the agreed number of Cryptocurrency Units, the Buyer agrees to register on the “Core Passport platform” and to purchase the “CorePass” for a fee. The Seller undertakes to subsequently grant the Buyer the power of disposal over the agreed number of Cryptocurrency Units.</li>
                <li>The Seller shall be obliged to render performance only after receipt of the consideration from the Buyer, but then within a reasonable period of time and without unnecessary delay.</li>
                <li>No general terms and conditions of the Contracting Parties shall apply beyond the ancillary provisions specified in Section 3.</li>
                <li>Changes to this contract must be made in writing; the use of e-mail shall satisfy this requirement.</li>
                <li>If any provision of this contract is or becomes void, invalid or unenforceable in whole or in part, the validity and enforceability of all remaining provisions shall not be affected thereby. The void, invalid or unenforceable provision shall, to the extent permitted by law, be replaced by that valid and enforceable provision which most closely approximates to the economic purpose pursued by the void, invalid or unenforceable provision in terms of subject matter, extent, time, place and scope. If this is not possible, the Contracting Parties shall replace any invalid or void provisions/sub-provisions of the contract by one or more new provision(s) which come as close as possible to the economic purpose of the original provision(s) of the contract.</li>
                <li>The Seller shall not be liable for any possible financial losses incurred by the Buyer in connection with the purchased Cryptocurrency Units. This shall apply in particular to the performance of Cryptocurrency Units.</li>
                <li>In the event of impossibility of performance of the contract, the Seller shall not be liable to the Buyer for any possible interest in performance or for any damage resulting from non-performance; this shall also apply to any impossibility of performance of the contract for which the Seller is responsible. In this case, the Seller undertakes to return the consideration received.</li>
                <li>The Seller reserves the right, at its discretion, to withdraw from the contract in whole or in part without further notice to the Buyer and, accordingly, to refund the consideration already received, in whole or in part.</li>
                <li>The subject matter and the form of this contract shall be subject to the confidentiality of the Contracting Parties.</li>
            </ol>
        </div>
    )
}

export default Terms;
