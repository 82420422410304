import React from "react";
import './index.scss';


const Transaction = (props: { show: boolean; onHide: any; activeCard: any }) => {

console.log("Active Card", props.activeCard)
    return(
        <>
           <div className="transaction-section col-12 col-md-10">
               <h1 className="font-weight-bold mt-3">Transactions</h1>
               <div className="detail-box">
                   <h3>Transaction #{props.activeCard.id}</h3>
                    <label className="d-block">Date</label>
                   <input className="d-block" disabled placeholder="16/03/2021" />

                   <label className="d-block">Asset</label>
                   <input className="d-block" disabled placeholder={props.activeCard.coinName} />

                   <label className="d-block">Quantity tokens</label>
                   <input className="d-block" disabled placeholder={props.activeCard.price} />

                   <label className="d-block">USD ($)</label>
                   <input className="d-block" disabled placeholder={props.activeCard.lastPrice} />

                   <label className="d-block">Type of Transactions</label>
                   <input className="d-block" disabled placeholder={props.activeCard.type} />
               </div>
           </div>
        </>
    )
}

export default Transaction;
