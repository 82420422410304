import React from "react";
import './styles.scss';
import {Row} from "react-bootstrap";
import Transaction from "../../components/Transaction";
import TransactionCard from "../../components/TransactionCard";
import btcLogo from "../../assets/images/coins/btcIcon.png";
import usdtLogo from "../../assets/images/coins/usdtIcon.png";
import sstLogo from "../../assets/images/coins/sstIcon.png";
import deposit from "../../assets/images/transaction/deposit.svg";
import withdraw from "../../assets/images/transaction/withdrawal.svg";
import swap from "../../assets/images/transaction/swap.svg";
import refer from "../../assets/images/transaction/refer.svg";

const coins = [
    {
        id: "1",
        coinName: "BTC",
        lastPrice: "1033.99",
        price: "0.0003251",
        coinImage: btcLogo,
        leftColor: "#f7931a",
        rightColor: "transparent",
        type: "Top Up",
        TrImg: deposit,
    },

    {
        id: "2",
        coinName: "BTC",
        lastPrice: "1033.99",
        price: "0.0003251",
        coinImage: btcLogo,
        leftColor: "#f7931a",
        rightColor: "transparent",
        type: "Top Up",
        TrImg: deposit,
    },

    {
        id: "3",
        coinName: "USDT",
        lastPrice: "1000.99",
        price: "1000.000",
        coinImage: usdtLogo,
        leftColor: "#50af95",
        rightColor: "transparent",
        type: "Withdrawal",
        TrImg: withdraw,
    },

    {
        id: "4",
        coinName: "USDT",
        lastPrice: "1000.99",
        price: "1000.000",
        coinImage: sstLogo,
        leftColor: "#515da899",
        rightColor: "#e31e2599",
        type: "Swap",
        TrImg: swap,
    },

    {
        id: "5",
        coinName: "USDT",
        lastPrice: "1000.99",
        price: "1000.000",
        coinImage: usdtLogo,
        leftColor: "#515da899",
        rightColor: "#e31e2599",
        type: "Referral",
        TrImg: refer,
    },
];

const TransactionList = () => {

    const [activeCard, setActiveCard] = React.useState('');

    const handleActiveCard = (coin: any) => {
        return setActiveCard(coin);
    }

  return (
    <Row>
        <div className="col-12 col-md-4 overflow-auto card-container">
            {coins.map((coin: any) => {
                return <TransactionCard handleActiveCard={handleActiveCard} coin ={coin} activeCard={activeCard} />
            })}
        </div>
        <div className="col-12 col-md-8">
            <Transaction show={true} onHide={false} activeCard={activeCard} />
        </div>
    </Row>
  );
};

export default TransactionList;
