import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {useMutation, useQuery} from "jsonapi-react";
import { toast } from 'react-toastify';
import {useInjectReducer} from "../../utils/redux-injectors";
import {userActions, userReducer, userSliceKey} from "../../types/coreStates/user/slice";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../types/coreStates/user/selectors";
import {handleJsonApiError, handleJsonApiErrors} from "../../helpers/handleErrors";
import "./style.scss";

const WithdrawModal = ({show, handleClose, ico, accounts, allowedCurrency}: {ico: any; accounts: any; show: boolean; allowedCurrency?: string; handleClose: (success?: boolean) => void }) => {
    const [amount, setAmount] = useState<undefined|number>();
    const [p_currency, setPCurrency] = useState<undefined|string>();
    useInjectReducer({ key: userSliceKey, reducer: userReducer });
    const user = useSelector(selectUser);

    const [address, setAddress] = useState<undefined | string>(user?.wallet_address);
    const [submitTransaction, {isLoading, data, error, errors}] = useMutation(['withdraws', {
    }]);
    useEffect(() => {
        if(!show) {
            setAmount(0)
        }
    }, [show])
    useEffect(() => {
        if(p_currency) {
            setAmount(0)
        }
    }, [p_currency])

    useEffect(() => {
        if(error) handleJsonApiError(error)
        if(errors && errors?.length > 0) handleJsonApiErrors(errors)
    }, [error, errors])
    useEffect(() => {
        if(data) {
            toast("Withdrawal Submitted, You will receive an email when withdrawal is completed.", {
                type: toast.TYPE.SUCCESS,
            });
            handleClose(true);
        }
    }, [data]);

    const account = accounts?.find((ac: { currency_id: any; balance: any; }) => ac.currency_id === p_currency)

    return (
        <>
            <Modal className="modalbox-s" show={show} onHide={handleClose} centered={true}>
                <Modal.Header className={'text-center justify-content-center'} closeButton>
                    <h2 className="text-center w-100">Withdraw</h2>
                </Modal.Header>
                <Modal.Body className={'modalbody'}>
                    <div className="container-fluid">
                        <p className={'m-0 p-0'}>
                            <span>Withdraw Your Commissions</span>
                        </p>
                        <div className="form">
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label className={'text-left'} htmlFor="amount">Amount</label>
                                    <input value={amount} onChange={e => setAmount(Number(e.target.value))} type="text" className="form-control" placeholder={`${ico?.currency?.id?.toUpperCase()} Amount`}/>
                                    <a onClick={() => {
                                        if(account) {
                                            setAmount(Number(parseFloat(account.balance || '0')) || 0)
                                        }
                                    }} className="text-danger font-weight-bold">Max.</a>
                                </div>
                                <div className="col-md-6  mb-2">
                                    <label htmlFor="currency">Currency</label>
                                    <select onChange={e => setPCurrency(e.target.value)} className="form-control">
                                        <option value="">Select Currency</option>
                                        {accounts?.filter((a: any) => !!allowedCurrency ? a.currency_id?.toLowerCase() === allowedCurrency : false)?.map((payment_currency: { currency_id: string; currency: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; withdrawal_enabled: boolean; }; }) => {
                                            if(!payment_currency?.currency?.withdrawal_enabled) return;
                                            if(payment_currency.currency_id && payment_currency?.currency?.name) {
                                                return (<option value={payment_currency.currency_id}>{payment_currency?.currency_id?.toLowerCase() === 'usdt' ? "USDT TRC-20" : payment_currency?.currency?.name}</option>);
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className={'text-center'}>
                                <input className="form-control" onChange={(e) => setAddress(e.target.value)} placeholder={`${account?.currency_id?.toLowerCase() === 'usdt' ? "USDT TRC-20" : account?.currency?.name || 'Wallet'} Address`} />
                            </div>
                        </div>
                        {/* <p className="font-weight-bold mx-3 mt-3 lh-sm">For your SST use a TRONLINK Wallet ONLY. The Klever Wallet is NOT supported at the moment.</p> */}
                    </div>
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-center align-items-center'}>
                    <div>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            if(isLoading) return;
                            submitTransaction({
                              currency: {
                                  id: p_currency,
                              },
                                rid: address,
                                amount: amount?.toFixed(8)
                            })
                        }}
                                disabled={!account?.currency?.withdrawal_enabled && [null, '', undefined].includes(address)}
                                variant={'secondary'}>
                            {isLoading ? <Spinner animation={'border'} /> : 'Withdraw Commissions' }
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WithdrawModal;
