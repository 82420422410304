import React from "react";
import "./styles.scss";
import {ChatDots} from "react-bootstrap-icons";

const HelpCenterButton = () => {
    return (
        <div className="fixed-bottom text-right p-3">
            <a href="https://t.me/corexsocialswap" target="_blank" className="btn btn-primary" rel="noreferrer"><ChatDots /> Help</a>
        </div>
    );
};

export default HelpCenterButton;
