import React, {useState, useCallback, useMemo, useEffect} from "react";
import WalletCard from "../../components/WalletCard";
import "./styles.scss";
import arrow from "./../../assets/images/coins/arrow.png";
import bgElement from "../../assets/images/coins/bg-element.png";
import TokenSold from "../../components/TokenSold";
import {useMutation, useQuery} from "jsonapi-react";
import PopupModal from "../../components/PopupModal";
import TradeModal from "../../components/TradeModal";
import {LoadingContainer} from "../../app/components/Atoms/LoadingContainer";
import WalletsTimer from "../../components/WalletsTimer";
import {useDispatch, useSelector} from "react-redux";
import {useInjectReducer} from "../../utils/redux-injectors";
import {
    topupActions,
    topupReducer,
    topupSliceKey,
} from "../../types/coreStates/toupWallet/slice";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import WithdrawModal from "../../components/WithdrawModal";
import Dropzone, {useDropzone} from "react-dropzone";
import {convertBase64} from "./../../utils/helpers/convertBase64";
import {toast} from "react-toastify";
import FileUploadModal from "../../components/FileUploadModal";
import {userReducer, userSliceKey} from "../../types/coreStates/user/slice";
import {selectUser} from "../../types/coreStates/user/selectors";

const Wallets = () => {
    const dispatch = useDispatch();
    useInjectReducer({key: userSliceKey, reducer: userReducer});
    const user = useSelector(selectUser);
    const [updatedIcoData, setUpdatedIcoData] = useState<any[] | undefined>(undefined);
    const [accounts, setAccounts] = useState<any>();
    useInjectReducer({key: topupSliceKey, reducer: topupReducer});
    const {data: accountsData, isLoading: accountssLoading, client} = useQuery([
        "accounts",
        {
            include: ["currency"],
        },
    ]);
    const {data: icosData, isLoading: icosLoading} = useQuery([
        "public/icos",
        {
            filter: {
                // currency: "core",
            },
            include: [
                "next_stage",
                "current_stage",
                "currency",
                "payment_currencies",
            ],
        },
    ]);


    useEffect(() => {
        setAccounts(accountsData)
    }, [accountsData])

    const ico = useMemo(() => {
        try {
            if (updatedIcoData) {
                return !(updatedIcoData && updatedIcoData?.length > 0) || updatedIcoData[0] || []
            }
            return !(icosData && icosData?.length > 0) || icosData[0] || []
        } catch (e) {
            return [];
        }
    }, [icosData, updatedIcoData]);

    const setShow = (open: boolean) => {
        dispatch(topupActions.openModal({}));
    };
    const [
        uploadDocument,
        {isLoading, data, error, errors, meta},
    ] = useMutation("user/document-upload");
    // const onDrop = useCallback(async (acceptedFiles) => {
    //   // Do something with the files
    // }, []);

    const [showClaim, setShowClaim] = useState(false);
    const [showTrade, setShowTrade] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState(false);

    return (
        <LoadingContainer isLoading={icosLoading || accountssLoading}>
            <div className="wallets">


                <h1 className="text-center wallets__heading my-3">
                    CORE Token Sale
                </h1>
                <p className={"text-center"}>
                    * Funds will be credited to the account within a few hours after
                    deposit!
                </p>
                <p className={"text-center "}>* No tokens are sold in the deposit process! Tokens can be bought during
                    the sales stages!</p>
                <div className="row mt-3 mb-5 justify-content-center align-items-center">
                    {
                        ico &&
                        ico?.payment_currencies?.map((payment_currency: any) => {
                            const account = accounts?.find(
                                (account: any) =>
                                    account.currency_id === payment_currency?.currency_details?.id
                            );
                            const coin = {
                                ...payment_currency?.currency_details,
                                ...account,
                                ico,
                            };
                            return (
                                <div className="col-12 col-md-5 col-lg-4 d-flex justify-content-center  mt-5">
                                    <WalletCard
                                        handleDeposit={(currency) => {
                                            // toast("Top-Up Disabled", {
                                            //   type: toast.TYPE.ERROR,
                                            // });
                                            dispatch(
                                                topupActions.openModal({selectedCurrency: currency})
                                            );
                                        }}
                                        coin={coin}
                                    />
                                </div>
                            );
                        })}
                    <div className="wallets__coin-arrow d-flex justify-content-center w-100 my-4">
                        <img src={arrow} alt=""/>
                    </div>
                    {accounts &&
                        accounts
                            .filter(
                                (account: { currency_id: string }) =>
                                    account.currency_id === "core"
                            )
                            .map((coin: any) => (
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <WalletCard
                                        handleDeposit={(currency) => {
                                        }}
                                        coin={{
                                            ...coin,
                                            ico,
                                        }}
                                    />
                                </div>
                            ))}
                </div>
                {ico && ico?.current_stage && (
                    <React.Fragment>
                        <div className={'w-100 justify-content-center align-items-center d-flex'}>
                            <div>
                                <Button
                                    variant={"secondary"}
                                    style={{
                                        fontSize: '1.3rem'
                                    }}
                                    onClick={() => {
                                        setShowTrade(true);
                                    }}
                                >
                                    Buy CORE now
                                </Button>
                            </div>
                        </div>
                        <TokenSold
                            total_sold={ico?.current_stage?.total_sold}
                            remaining={ico?.current_stage?.remaining}
                        />
                    </React.Fragment>
                )}
                {ico && !ico?.current_stage && ico?.next_stage && (
                    <React.Fragment>
                        <WalletsTimer
                            date={ico?.next_stage?.start_date}
                            stage={ico?.next_stage}
                        />
                    </React.Fragment>
                )}

                <div className="wallets__buttons">
                    <div className="d-flex justify-content-center align-items-center w-100 mb-2">
                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip id={"tooltip-top"}>
                                    Relevant only for paper contract sales.
                                </Tooltip>
                            }
                        >
                            <Button
                                variant={"secondary"}
                                className={"mt-2 btn-green"}
                                onClick={() => {
                                    setShowFileUpload(true);
                                }}
                            >
                                Upload Contract
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>

                {ico && ico?.current_stage && (
                    <TradeModal
                        show={showTrade}
                        handleClose={(success = undefined) => {
                            if (success) {
                                client
                                    .fetch([
                                        "accounts",
                                        {
                                            include: ["currency"],
                                        },
                                    ])
                                    .then(({data}) => {
                                        setAccounts(data);
                                    });
                                client
                                    .fetch([
                                        "public/icos",
                                        {
                                            filter: {
                                                currency: "core",
                                            },
                                            include: [
                                                "next_stage",
                                                "current_stage",
                                                "currency",
                                                "payment_currencies",
                                            ],
                                        },
                                    ])
                                    .then(({data}) => {
                                        setUpdatedIcoData(ico);
                                        // ico = data;
                                    });
                            }
                            setShowTrade(false);
                        }}
                        ico={ico}
                    />
                )}
                <WithdrawModal
                    show={showWithdraw}
                    handleClose={() => setShowWithdraw(false)}
                    ico={ico}
                    accounts={accountsData}
                />
                <FileUploadModal
                    show={showFileUpload}
                    handleClose={() => setShowFileUpload(false)}
                />
            </div>
            <div className="bg-wallet-footer" style={{backgroundImage: `url(${bgElement})`}}></div>
        </LoadingContainer>
    );
};

export default Wallets;
