import React, {useEffect, useMemo, useState} from "react";
import "./style.scss";
import "../../components/WalletCard/styles.scss";
import {Button, Row} from "react-bootstrap";
import ReferFriends from "../../components/ReferFriends";
import {useQuery} from "jsonapi-react";
import moment from "moment";
import {LoadingContainer} from "../../app/components/Atoms/LoadingContainer";
import {useInjectReducer} from "../../utils/redux-injectors";
import {userReducer, userSliceKey} from "../../types/coreStates/user/slice";
import {useSelector} from "react-redux";
import {selectUser} from "../../types/coreStates/user/selectors";
import WithdrawModal from "../../components/WithdrawModal";
import {topupReducer, topupSliceKey} from "../../types/coreStates/toupWallet/slice";

const ReferFriendsPage = () => {
    useInjectReducer({key: userSliceKey, reducer: userReducer});
    const user = useSelector(selectUser);
    const [referFriendsModalShow, setReferFriendsModalShow] = React.useState(false);
    const [dexReferFriendsModalShow, setDexReferFriendsModalShow] = React.useState(false);
    const [page, setPage] = useState(1);
    const [showWithdraw, setShowWithdraw] = useState(false);
    const {data: referralData, isLoading: icosLoading, meta} = useQuery(['referrals', {
        page: {
            number: page,
            size: 10,
        },
    }]);
    const [accounts, setAccounts] = useState<any>();
    const { data: accountsData, isLoading: accountssLoading, client } = useQuery([
        "accounts",
        {
            include: ["currency"],
        },
    ]);


    useEffect(() => {
        setAccounts(accountsData)
    }, [accountsData])



    const total = meta?.total_commission;

    return (
        <>
            <h1 className="font-weight-bold heading text-center text-white">
                Refer Your Friends
            </h1>
            <p className="text-center pt-3 text-white">
                Invite a friend and get 3% of the amount that you friend is buying CORE Token to your wallet.
            </p>
            <div className="col-12 d-flex justify-content-center py-4">
                <a
                    className="btn btn-secondary"
                    onClick={() => setReferFriendsModalShow(true)}
                >
                    Get Your Referral Link
                </a>
                <a
                    className="btn btn-secondary ml-3"
                    onClick={() => setShowWithdraw(true)}
                >
                    Withdraw Commissions
                </a>
            </div>
            {user?.my_sst_address && <div className="col-12 d-flex justify-content-center py-4">
                <a
                    className="btn btn-secondary"
                    onClick={() => setDexReferFriendsModalShow(true)}
                >
                    Get Your Dex Referral Link
                </a>
            </div>}
            <div className="col-12 col-md-10 mx-auto">
                <Row>
                    <div className="col-12 col-md-6">
                        <div className="wallet-card-container w-90 my-4 mx-auto"
                             style={{background: `linear-gradient(to right, rgb(81, 167, 112), rgb(30, 228, 108)`}}>
                            <div className="wallet-card">
                                <div className="d-flex align-items-center wallet-card__content">
                                    {/*<img*/}
                                    {/*    className="mr-4 wallet-card__coin-image coin-image"*/}
                                    {/*    src=""*/}
                                    {/*    alt=""*/}
                                    {/*/>*/}
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="wallet-card__coin">Total Partners</span>
                                            {/*<span className="wallet-card__price last-price">0</span>*/}
                                        </div>
                                        <div>
                                        <span className="wallet-card__last-price price">
                                            {meta?.page?.total || 0}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="wallet-card-container w-90 my-4 mx-auto"
                             style={{background: `linear-gradient(to right, rgb(81, 167, 112), rgb(30, 228, 108)`}}>
                            <div className="wallet-card">
                                <div className="d-flex align-items-center wallet-card__content">
                                    {/*<img*/}
                                    {/*    className="mr-4 wallet-card__coin-image coin-image"*/}
                                    {/*    src=""*/}
                                    {/*    alt=""*/}
                                    {/*/>*/}
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="wallet-card__coin">Total Commission</span>
                                            {/*<span className="wallet-card__price last-price">0</span>*/}
                                        </div>

                                        <div>
                                        <span className="wallet-card__last-price price">
                                            {Number(total || 0)?.toFixed(8)} USDT
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>

            <Row className="mt-5 text-dark">
                <div className="col-12 col-md-10 mx-auto">
                    <div className="bgc">
                        <div className="table-responsive text-dark">
                            <table className="table text-dark">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Commission</th>
                                </tr>
                                </thead>

                                <tbody>
                                <LoadingContainer isLoading={icosLoading}>
                                    {referralData && referralData.map((ico: any) => (
                                        <tr>
                                            <td>{moment(ico?.created_at).format('D/M/Y')}</td>
                                            <td>{ico?.name}</td>
                                            <td>{ico?.commission} USDT</td>
                                        </tr>
                                    ))}
                                </LoadingContainer>

                                </tbody>
                            </table>
                            <div className={'d-flex justify-content-between mt-3 mx-1'}>
                                <Button variant={'secondary'} disabled={page <= 1} onClick={(e) => {
                                    e.preventDefault();
                                    if (page <= 1) return;
                                    setPage(page - 1);
                                }}>Prev</Button>
                                <Button variant={'secondary'} disabled={page >= meta?.page?.lastPage} onClick={(e) => {
                                    e.preventDefault();
                                    if (page >= meta?.page?.lastPage) return;
                                    setPage(page + 1);
                                }}>Next</Button>
                            </div>

                            <p className={"mb-0 mt-3 text-center"}>Page {meta?.page?.currentPage} of {meta?.page?.lastPage}</p>
                        </div>
                    </div>
                </div>
            </Row>

            <WithdrawModal
                show={showWithdraw}
                handleClose={() => setShowWithdraw(false)}
                accounts={accounts}
                allowedCurrency={'usdt'}
             ico={{
                 currency: {
                     id: "USDT"
                 }
             }}/>

            <ReferFriends
                show={referFriendsModalShow}
                onHide={() => setReferFriendsModalShow(false)}
            />
            {user?.my_sst_address && <ReferFriends
                dex={true}
                show={dexReferFriendsModalShow}
                onHide={() => setDexReferFriendsModalShow(false)}
            />}
        </>
    );
};

export default ReferFriendsPage;
