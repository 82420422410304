import React from "react";
import "./App.scss";
import {Provider} from "react-redux";

import Footer from "./components/Footer";

import {BrowserRouter as Router} from "react-router-dom";
import {ApiClient, ApiProvider} from 'jsonapi-react'
import {schema} from './schema';
import {getCookie} from "./helpers/getCookie";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {configureAppStore} from "./store/configureStore";
import RenderRoutesComponent from "./routes";
import {jsonApiUrl, sanctumUrl} from "./api/config";
import HelpCenterButton from "./components/HelpCenterButton";

const client = new ApiClient({
    url: jsonApiUrl(),
    // @ts-ignore
    fetch: async function (url, options) {
        // @ts-ignore
        if (!options?.method) {
            return fetch(url, {
                ...options,
                credentials: 'same-origin',
                headers: {
                    // @ts-ignore
                    ...options?.headers,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
            });
        }
        return fetch(sanctumUrl()+'/csrf-cookie', {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                // @ts-ignore
                ...options?.headers,
                'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
            },
        }).then(response => {
            return fetch(url, {
                ...options,
                credentials: 'same-origin',
                headers: {
                    // @ts-ignore
                    ...options.headers,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
            });
        })
            .catch(error => {
                console.log('Error while fetching CSRF token!', error);
            })
    },
    schema,
})

const store = configureAppStore();

const App = () => {
    return (
        <Provider store={store}>
        <ApiProvider client={client}>
            <Router>
                <div className="app">
                    <RenderRoutesComponent />
                    <Footer/>
                </div>
            </Router>
            <ToastContainer />
            <HelpCenterButton />
        </ApiProvider>
        </Provider>
    );
};

export default App;
