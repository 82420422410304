import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { PASSWORD_REGEX } from "../../helpers/emailValidation";
import { API } from "../../api";
import { handleApiError } from "../../helpers/handleErrors";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import {Spinner} from "react-bootstrap";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  // @ts-ignore
  const token: string | undefined = urlParams?.get("reset_token") || urlParams?.get("token");
  const email: string | null | undefined = urlParams?.get("email");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const history = useHistory();

  const handleResetPasswordSubmit = (e: any) => {
    e.preventDefault();
    // if (!resetPassword.match(PASSWORD_REGEX)) {
    //   setInvalidPassword(true);
    //   setTimeout(() => setInvalidPassword(false), 5000);
    // } else
      if (resetPassword !== confirmPassword) {
      setPasswordsMatch(true);
      setTimeout(() => setPasswordsMatch(false), 5000);
    } else {
      if (loading) return;
      setLoading(true);
      API.post({
        apiVersion: "apiUrl",
      })("/reset-password", {
        token,
        email,
        password: resetPassword,
        password_confirmation: confirmPassword,
      })
        .then(() => {
          setLoading(false);
          history.push("/");
        })
        .catch((e) => {
          setLoading(false);
          handleApiError(e);
        });
    }

    // setResetPassword("");
    // setConfirmPassword("");
  };

  return (
    <div className="reset-password p-5 d-flex flex-column justify-content-center align-items-center">
      <h3>Reset Password</h3>

      <form
        onSubmit={handleResetPasswordSubmit}
        className="reset-password__form w-100"
        action="#"
      >
        <div className="mt-4">
          <input
            autoComplete={"password"}
            required
            type="password"
            placeholder="New Password"
            onChange={(e) => setResetPassword(e.target.value)}
            value={resetPassword}
          />
          {/*<p*/}
          {/*  className={`mt-2 reset-password__password-info ${*/}
          {/*    resetPassword && invalidPassword ? "text-danger" : ""*/}
          {/*  }`}*/}
          {/*>*/}
          {/*  Password must contain at least 8 characters, at least one capital*/}
          {/*  letter and one digit*/}
          {/*</p>*/}
        </div>
        <div>
          <input
            autoComplete={"password"}
            required
            type="password"
            placeholder="Repeat Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          {passwordsMatch && (
            <p className="mt-2 text-danger reset-password__password-info">
              Passwords don't match!
            </p>
          )}
        </div>
        <button className="btn btn-secondary w-100">
          {loading ? <Spinner animation={"border"} /> : "Change Password"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
