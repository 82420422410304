import React from "react";
import "./styles.scss";
import btcLogo from "./../../assets/images/coins/btcIcon.png";
import ethLogo from "../../assets/images/coins/ethIcon.png";
import usdtLogo from "../../assets/images/coins/usdtIcon.png";
import sstLogo from "../../assets/images/coins/sstIcon.png";
import coreLogo from "../../assets/images/coins/coreIcon.svg";
import bchLogo from "../../assets/images/coins/bchIcon.png";
import dashLogo from "../../assets/images/coins/dashIcon.png";
import xmrLogo from "../../assets/images/coins/xmrIcon.svg";
import ltcLogo from "../../assets/images/coins/ltcIcon.png";
import zecLogo from "../../assets/images/coins/zecIcon.png";
import dogeLogo from "../../assets/images/coins/dogeIcon.png";
import Transaction from "../Transaction";

interface ownProps {
  coin: any;
  handleDeposit: (currency: string) => void;
}
const coins = [
  {
    id: "btc",
    coinName: "BTC",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: btcLogo,
    leftColor: "#f7931a",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "eth",
    coinName: "ETH",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: ethLogo,
    leftColor: "#8fd7f7",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "usdt",
    coinName: "USDT",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: usdtLogo,
    leftColor: "#50af95",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "bch",
    coinName: "BCH",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: bchLogo,
    leftColor: "#8dc351",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "dash",
    coinName: "DASH",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: dashLogo,
    leftColor: "#008de4",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "xmr",
    coinName: "XMR",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: xmrLogo,
    leftColor: "#f26822",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "ltc",
    coinName: "LTC",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: ltcLogo,
    leftColor: "#345d9d",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "zec",
    coinName: "ZEC",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: zecLogo,
    leftColor: "#cf8724",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "doge",
    coinName: "DOGE",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: dogeLogo,
    leftColor: "#ba9f33",
    rightColor: "transparent",
    showButton: "true",
  },

  {
    id: "core",
    coinName: "SST",
    lastPrice: "50000.00",
    price: "1.00000000",
    coinImage: coreLogo,
    leftColor: "#00B74F",
    rightColor: "transparent",
    showButton: "false",
  },
];
const WalletCard = (props: ownProps) => {
  const coin = coins.find((coin) => {
    return props.coin.currency_id === coin.id;
  });
  const account = props.coin;
  if (!coin) {
    return <div></div>;
  }
  const currentPrice =
    props.coin?.ico?.current_stage?.base_price ||
    props.coin?.ico?.next_stage?.base_price;

  // const usdtTextChange =
  //   account?.currency?.id === "usdt" && account?.currency?.id;

  return (
    <div
      className="wallet-card-container w-90"
      style={{
        background: `linear-gradient(to right, ${coin.leftColor} 0%, ${coin.leftColor} 100%`,
    }}
      >
      <div className="wallet-card">
        <div className="d-flex align-items-center wallet-card__content">
          <img
            className="mr-4 wallet-card__coin-image"
            src={coin.coinImage}
            alt=""
          />
          <div>
            <div className="d-flex justify-content-between align-items-center pb-2">
              <span className="wallet-card__coin">
                {account?.currency?.id}
              </span>
                <span className="wallet-card__price">
                  {parseFloat(account.balance).toFixed(8)}
                </span>
            </div>
            <div>
              <span className="wallet-card__last-price">
                {coin.id === "core" &&
                  `$ ${(
                    parseFloat(currentPrice || "0") *
                    parseFloat(account?.balance)
                  ).toFixed(6)}`}
              </span>
              {/*{coin.id === "core" && (*/}
              {/*    <span className="wallet-card__price d-block">*/}
              {/*  {parseFloat(account.balance).toFixed(8)}*/}
              {/*</span>*/}
              {/*)}*/}
              {coin.id !== "core" && (
                <div
                  onClick={() => props.handleDeposit(coin?.id)}
                  className="btn"
                  style={{ background: `${coin.leftColor}` }}
                >
                  Top-Up {account?.currency?.id?.toUpperCase()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// {(ico?.current_stage?.base_price || ico?.next_stage?.base_price) && (
//   <Nav.Link onClick={() => {}} className={"mobile-price"}>
//     SST $
//     {parseFloat(
//       ico?.current_stage?.base_price || ico?.next_stage?.base_price || 0
//     )}
//   </Nav.Link>
// )}

export default WalletCard;
