import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col className="text-center py-3">
            <p className="footer__text mb-0">
              Copyright &copy; 2022 socialswap.io All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
