import { call, put, takeLatest } from "redux-saga/effects";
import { userActions } from "./slice";
import { API, RequestOptions } from "../../../api";

const config: RequestOptions = {
  apiVersion: "apiUrl"
};
const logoutConfig: RequestOptions = {
  apiVersion: "authUrl"
};
export function* fetchUser() {
  try {
    const { data } = yield call(API.get(config), "/user/me");
    yield put({ type: userActions.fetchUserSuccess.type, payload: data });
  } catch (e) {
    yield put({ type: userActions.fetchUserError.type, payload: undefined });
  }
}

export function* logoutUser() {
  try {
    const { data } = yield call(API.post(logoutConfig), "/logout");
    yield put({ type: userActions.logoutSuccessAction.type, payload: data });
  } catch (e) {
    yield put({ type: userActions.logoutErrorAction.type, payload: undefined });
  }
}

export function* userSaga() {
  yield takeLatest(userActions.fetchUser.type, fetchUser);
  yield takeLatest(userActions.logoutFetchAction.type, logoutUser);
}
