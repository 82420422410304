import {
    HOME_ROUTE,
    DASHBOARD,
    PRIVATE_ROUTE_PARENT,
    TRANSACTION_LIST,
    REFER_FRIENDS,
    RESET_PASSWORD_ROUTE,
    FAQ,
    TRAINING,
    TERMS_ROUTE, LEADERBOARD
} from "./constants";
import Home from "../screens/Home";
import Wallets from "../screens/Wallets";
import TransactionList from "../screens/TransactionList";
import ReferFriendsPage from "../screens/ReferFriends";
import ResetPassword from "../components/ResetPassword";
import Faqs from "../components/Faqs";
import Training from "../screens/Training";
import Terms from "../components/Terms";
import Leaderboard from "../screens/Leaderboard";

export interface RouteInterface {
    key: string;
    path: string;
    parent: string;
    component: any;
    exact?: boolean;
    name?: string;
    icon?: string;
    hideSidebar?: boolean;
    hideNavbar?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    redirect_to?: string;
    isDevelopment?: boolean;
}

export const ROUTES: RouteInterface[] = [
    {
        key: "RESET_PASSWORD_ROUTE",
        path: RESET_PASSWORD_ROUTE,
        component: ResetPassword,
        exact: true,
        // isPrivate: true,
        parent: "",
    },
    {
        key: "FAQ",
        path: FAQ,
        component: Faqs,
        exact: true,
        parent: "",
    },
    {
        key: "Terms",
        path: TERMS_ROUTE,
        component: Terms,
        exact: true,
        parent: "",
    },
    {
        key: "TRAINING",
        path: TRAINING,
        component: Training,
        exact: true,
        // isPrivate: true,
        parent: "",
    },
    {
        key: "LEADERBOARD",
        path: LEADERBOARD,
        component: Leaderboard,
        exact: true,
        isPrivate: true,
        parent: PRIVATE_ROUTE_PARENT,
    },
    {
        key: "REFERFRIENDS",
        path: REFER_FRIENDS,
        component: ReferFriendsPage,
        exact: true,
        isPrivate: true,
        // isDevelopment: true,
        parent: PRIVATE_ROUTE_PARENT,
    },
    {
        key: "DASHBOARD",
        path: PRIVATE_ROUTE_PARENT,
        component: Wallets,
        exact: true,
        isPrivate: true,
        parent: "",
    },
    {
        key: "TRANSACTION_LIST",
        path: TRANSACTION_LIST,
        component: TransactionList,
        exact: true,
        // isPrivate: true,
        isDevelopment: true,
        parent: "",
    },
    {
        key: "HOME",
        path: HOME_ROUTE,
        component: Home,
        exact: true,
        parent: "",
    },
];
