import { createSlice } from "../../../utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { TopupState } from "./types";

export const initialState: TopupState = {
  modalOpen: false,
  selectedCurrency: undefined
};

const topupSlice = createSlice({
  name: "topupState",
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<{selectedCurrency?: string|undefined}>) {
      state.modalOpen = true;
      state.selectedCurrency = action.payload.selectedCurrency;
    },
    closeModal(state, action: PayloadAction<{ open?: boolean }>) {
      state.modalOpen = action.payload?.open || false;
      state.selectedCurrency = undefined;
    },
    changeCurrency(state, action: PayloadAction<{selectedCurrency: string}>) {
      state.selectedCurrency = action.payload.selectedCurrency;
    }
  }
});

/*
 * `reducer` will be used to add this slice to our Redux Store
 * `actions` will be used to trigger change in the state from where ever you want
 * `name` will be used to add this slice to our Redux Store
 */
export const {
  actions: topupActions,
  reducer: topupReducer,
  name: topupSliceKey
} = topupSlice;
