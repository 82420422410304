import * as React from "react";
import {useEffect, useMemo} from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./layouts";
import { useInjectReducer } from "../utils/redux-injectors";
import { useDispatch, useSelector } from "react-redux";
import { selectIsUserLoggedIn, selectUser } from "../types/coreStates/user/selectors";
import { userActions, userReducer, userSliceKey } from "../types/coreStates/user/slice";
import { useInjectSaga } from "redux-injectors";
import { userSaga } from "../types/coreStates/user/saga";
import { PRIVATE_ROUTE_PARENT } from "./constants";
import SecondPopupModal from "../components/SecondPopupModal";
import {topupActions, topupReducer, topupSliceKey } from "../types/coreStates/toupWallet/slice";
import {selectIsModalOpen} from "../types/coreStates/toupWallet/selectors";
import {useQuery} from "jsonapi-react";

function RenderRoutesComponent({ history }: RouteComponentProps): JSX.Element {
  // // Inject the slice to redux
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectReducer({ key: topupSliceKey, reducer: topupReducer });
  useInjectSaga({ key: userSliceKey, saga: userSaga });
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const topUpModalShow = useSelector(selectIsModalOpen);
    const { data: icosData } = useQuery([
        "public/icos",
        {
            filter: {
                // currency: "core",
            },
            include: [
                "next_stage",
                "current_stage",
                "currency",
                "payment_currencies",
            ],
        },
    ]);

    const ico = useMemo(() => {
        try {
            return !(icosData && icosData?.length > 0) || icosData[0] || []
        } catch (e) {
            return [];
        }
    }, [icosData]);
  const dispatch = useDispatch();
  const setTopUpModalShow = (open: boolean) => {
      dispatch(topupActions.closeModal({}))
  }
  useEffect(
    () => {
      if (!isLoggedIn) {
        dispatch(userActions.fetchUser(null));
      }
    },
    [!isLoggedIn]
  );
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={PRIVATE_ROUTE_PARENT}
          render={props => <PrivateRoute {...props} />}
        />
        <Route path={"/"} render={props => <PublicRoute {...props} />} />
      </Switch>
        <SecondPopupModal
            show={topUpModalShow}
            handleClose={() => setTopUpModalShow(false)}
            ico={ico}
        />
    </React.Fragment>
  );
}

export default withRouter(RenderRoutesComponent);
