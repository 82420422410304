import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import redIcon from "./../../assets/images/header/red-icon.png";
import blueIcon from "./../../assets/images/header/blue-icon.png";
import logo from "./../../assets/images/home/socialswap-logo.png";
import "./styles.scss";
import { API } from "../../api";
import { useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "../../utils/redux-injectors";
import {
  userActions,
  userReducer,
  userSliceKey,
} from "../../types/coreStates/user/slice";
import { userSaga } from "../../types/coreStates/user/saga";
import { handleApiError } from "../../helpers/handleErrors";
import { useHistory } from "react-router";
import { AUTHORISED_REDIRECT_ROUTE } from "../../routes/constants";
import ForgotPassword from "../ForgotPassword";
import { toast } from "react-toastify";

const SignInModal = (props: {
  show: boolean;
  onHide: any;
  onSignUpClick: () => void;
  isLoggedIn: boolean;
  showRegisterModal: () => void;
}) => {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: userSaga });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordModalShow, setForgotPasswordModalShow] = React.useState(
    false
  );
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    API.post({
      apiVersion: "apiUrl",
    })("/login", {
      email,
      password,
    })
      .then(() => {
        dispatch(userActions.fetchUser(null));
        setLoading(false);
        history.push(AUTHORISED_REDIRECT_ROUTE);
        props.onHide();
      })
      .catch((e) => {
        setLoading(false);
        handleApiError(e);
      });
  };

  const handleForgotPasswordSubmit = (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    API.post({
      apiVersion: "apiUrl",
    })("/forgot-password", {
      email: forgotPasswordEmail,
    })
      .then(() => {
        setLoading(false);
        props.onHide();
        toast("Email sent to your inbox!", {
          type: "success",
        });
      })
      .catch((e) => {
        setLoading(false);
        handleApiError(e);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="Signin-modal text-center"
      style={{ paddingRight: "0" }}
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title
          className="align-items-center w-100"
          id="contained-modal-title-vcenter"
        >
          {!forgotPasswordModalShow ? (
            <> 
            <div className="row">
              <div className="col">
                  <img
                    style={{ maxWidth: "180px" }}
                    className="img-fluid"
                    src={logo}
                    alt=""
                  />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h4 className="Signin-modal__label mb-0">Login</h4>
              </div>
            </div>
            </>
          ) : (
            <div className="d-flex flex-column">
              <h4>Forgot Password</h4>
              {/* <p>
                If you forgot your password, well, then we’ll email you
                instructions to reset your password.
              </p> */}
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!forgotPasswordModalShow ? (
          <>
            <form className="Signin-modal__form" onSubmit={onSubmit}>
              <div>
                <input
                  autoComplete={"email"}
                  required
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div>
                <input
                  autoComplete={"password"}
                  required
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>

            <div className="w-100 mt-4 d-flex justify-content-center align-items-center mb-2">
              <button className="login-btn btn btn-secondary w-100" onClick={onSubmit}>
                {loading ? <Spinner animation={"border"} /> : "Login"}
              </button>
            </div>
            </form>

            {!props.isLoggedIn && (
              <a
                className="Signin-modal__forgot-password-link"
                onClick={() => {
                  setForgotPasswordModalShow(true);
                }}
              >
                Forgot Password
              </a>
            )}
            {/* <ForgotPassword
              show={forgotPasswordModalShow}
              onHide={() => setForgotPasswordModalShow(false)}
            /> */}
          </>
        ) : (
          <form onSubmit={handleForgotPasswordSubmit}>
            <input
              type="text"
              className="mb-3"
              placeholder="Email"
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
            />

            <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-secondary px-5">
                {loading ? <Spinner animation={"border"} /> : "Send Email"}
              </button>
              <a
                className="Signin-modal__forgot-password-link"
                onClick={() => {
                  setForgotPasswordModalShow(false);
                }}
              >
                Return Login
              </a>
            </div>
          </form>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0">
        {!forgotPasswordModalShow ? (
          <div className="w-100 mt-4 d-flex justify-content-center align-items-center">
            <a
              className="Signin-modal__login-link"
              onClick={(e) => {
                e.preventDefault();
                props.onSignUpClick();
              }}
            >
              Sign Up
            </a>
          </div>
        ) : (
          <div className="text-left w-100">
            {/* <a
              className="Signin-modal__forgot-password-link"
              onClick={() => {
                setForgotPasswordModalShow(false);
                props.onHide();
                props.showRegisterModal();
              }}
            >
              Register Now
            </a> */}
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SignInModal;
