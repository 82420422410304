import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { RouteInterface, ROUTES } from "../routes";
import { NotFoundPage } from "../../app/containers/NotFoundPage/Loadable";
import { useInjectReducer } from "../../utils/redux-injectors";
import { userReducer, userSliceKey } from "../../types/coreStates/user/slice";
import { useSelector } from "react-redux";
import {
  selectIsUserLoading,
  selectIsUserLoggedIn,
  selectIsUserRole,
  selectIsVerifiedIn
} from "../../types/coreStates/user/selectors";
import { Redirect, useLocation } from "react-router-dom";
import {Container, Spinner} from "react-bootstrap";
import { AUTHORISED_REDIRECT_ROUTE } from "../constants";
import Bg from "../../assets/images/home/homebg.png";
import Header from "../../components/Header";

export function PublicRoute(props: RouteComponentProps) {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const isVerified = useSelector(selectIsVerifiedIn);
  const isLoading = useSelector(selectIsUserLoading);
  const location = useLocation().pathname;
  const isUser = useSelector(selectIsUserRole);

  return (
      <React.Fragment>
          <Header />
              <Container fluid>
                  <Switch>
                      {getRoutes(ROUTES, isLoading, isLoggedIn && isUser, isVerified, location)}
                      <Route
                          path={''}
                          component={NotFoundPage}
                      />
                  </Switch>
              </Container>
      </React.Fragment>
  );
}

const getRoutes = (
  routes: RouteInterface[],
  isLoading: boolean,
  isLoggedIn: boolean,
  isVerified: boolean,
  location: string
) => {
  return routes.map((prop, key) => {
    if (prop.isPublic) {
      return (
        <PublicRouter
          {...prop}
          path={prop.parent + prop.path}
          component={prop.component}
          redirect_to={prop.redirect_to}
          key={key}
          isLoggedIn={isLoggedIn}
          isVerified={isVerified}
          location={location}
          isLoading={isLoading}
        />
      );
    } else if (!prop.isPublic && !prop.isPrivate) {
      return (
        <Route
          {...prop}
          path={prop.parent + prop.path}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

function PublicRouter({
                        component: Component,
                        isLoading,
                        isLoggedIn,
                        isVerified,
                        location,
                        redirect_to,
                        ...rest
                      }: any) {
  if (isLoading)
    return (
      <Route
        render={() => (
          <div className={"d-flex justify-content-center align-item-center"}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      />
    );

  if (isLoggedIn && isVerified) {
    return (
      <Route>
        <Redirect to={AUTHORISED_REDIRECT_ROUTE} />
      </Route>
    );
  }
  if (redirect_to) {
    return (
      <Route>
        <Redirect {...rest} to={redirect_to} />
      </Route>
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
}

// export const PublicRoute = connect(null, null)(PublicRouteComponent as any);
