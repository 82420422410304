import React, {useEffect, useState} from "react";
import './style.scss';
import LeftImg from '../../assets/images/modal/modal-icon.svg'
import RightImg from '../../assets/images/modal/close.svg'
import {Dropdown, Modal, Row, Spinner} from "react-bootstrap";
import coin1 from "./../../assets/images/coins/btcIcon.png";
import coin2 from  "./../../assets/images/coins/ethIcon.png";
import coin3 from "./../../assets/images/coins/usdtIcon.png";
import {useMutation} from "jsonapi-react";
import {handleJsonApiError, handleJsonApiErrors} from "../../helpers/handleErrors";
import {useInjectReducer} from "../../utils/redux-injectors";
import {topupActions, topupReducer, topupSliceKey} from "../../types/coreStates/toupWallet/slice";
import {useDispatch, useSelector} from "react-redux";
import {selectedCurrency, selectIsModalOpen} from "../../types/coreStates/toupWallet/selectors";
import {toast} from "react-toastify";

interface Currency {
    id: string;
    icon: any;
    icon_url?: any;
}

const paymentCurrencies: Currency[] = [
    {
        id: 'btc',
        icon: coin1
    }, {
        id: 'eth',
        icon: coin2
    }, {
        id: 'usdt',
        icon: coin3
    },
];

const SecondPopupModal = ({show, handleClose, ico}: { show: boolean, handleClose: () => void, ico: any }) => {
    const dispatch = useDispatch();
    useInjectReducer({ key: topupSliceKey, reducer: topupReducer });
    const cu = useSelector(selectedCurrency);
    const currency = ico?.payment_currencies?.find((cur: { currency_details: { id: string | undefined; }; }) => cur?.currency_details.id === cu);
    const setCurrency = (pc: Currency) => {
        dispatch(topupActions.changeCurrency({selectedCurrency: pc.id}))
    }
    // const [currency, setCurrency] = useState<undefined | Currency>();
    const [amount, setAmount] = useState<undefined | string>();
    const [addDeposit, {isLoading, data, error, errors, meta}] = useMutation('deposits/plisio')
    useEffect(() => {
        if (error) handleJsonApiError(error)
        if (errors && errors?.length > 0) {
            handleJsonApiErrors(errors)
        }
    }, [error, errors])
    useEffect(() => {
        if (meta?.url) {
            window.location = meta?.url;
        }
    }, [meta])


    return (
        <>
            <Modal className="modalbox-v" show={show} onHide={handleClose} centered={true}>
                <Modal.Header className={'heading'} closeButton>
                    <img src={LeftImg} alt="Icon" style={{maxWidth: '60px'}}/>
                </Modal.Header>
                <Modal.Body className={'modalbody'}>
                    <div className="text-dark">
                        <h2 className="pb-4">Top-Up via Plisio</h2>
                        <Row className="row">
                            <div className="col-12 col-md-6 mb-4">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {currency ?
                                            <React.Fragment>
                                                {/* @ts-ignore */}
                                                {currency?.currency_details?.id.toUpperCase()}
                                            </React.Fragment>
                                            : 'Select Currency'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {ico?.payment_currencies?.map((pc: { currency_details: Currency; }) => (
                                            <Dropdown.Item key={pc?.currency_details?.id} onClick={() => {
                                                setCurrency(pc?.currency_details)
                                            }}>{pc?.currency_details?.id.toUpperCase() === 'USDT' ?  pc?.currency_details?.id.toUpperCase() : pc?.currency_details?.id.toUpperCase()}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-12 col-md-6">
                                <input className="form-control" placeholder="Enter Amount"
                                       style={{
                                           // width: '80%',
                                           margin: '0 auto',
                                           fontSize: '1rem'
                                       }}
                                       onChange={(e) => setAmount(e.target.value)}/>
                            </div>
                        </Row>

                        <p>
                            * Funds will be credited to your account within a few hours after deposit started! The minimum deposit is 150 USDT. No tokens are sold in this stage.
                        </p>
                        <p>
                            * In the next step you can choose your payment method. Available: USDT, BTC, ETH, BCH, LTC, DOGE, DASH or MONERO
                        </p>
                        <p>
                            * A 2% fee will be deducted from the amount when payed in BTC, ETH, BCH, LTC, DOGE, DASH or MONERO
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-center'}>
                    <div>
                        <div className="btn-link cursor-pointer" onClick={() => {
                            if (isLoading) return;
                            if (!currency?.currency_details?.id) {
                                alert("Currency Not Found")
                                return;
                            }
                            if (!amount) {
                                alert("Amount Not Found")
                                return;
                            }
                            // toast('Top-Up Disabled', {
                            //     type: toast.TYPE.ERROR,
                            // })
                            addDeposit({
                                amount: amount,
                                currency: currency?.currency_details?.id?.toLowerCase(),
                                options: {
                                    ico_stage_id: ico?.current_stage?.id
                                },
                            })
                        }}>{isLoading ? <Spinner animation={"border"}/> : 'To the next step'}</div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SecondPopupModal;
