import React, {useEffect, useState} from "react";
import {useMutation} from "jsonapi-react";
import {Modal, Spinner, Button} from "react-bootstrap";
import {convertBase64} from "../../utils/helpers/convertBase64";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import "./styles.scss";

const FileUploadModal = ({show, handleClose}: { show: boolean; handleClose: () => void }) => {
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [name, setName] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [backgroundImageDisplay, setBackgroundImageDisplay] = useState<any>();
    const [invalidFileType, setInvalidFileType] = useState<any>(false);

    const [uploadDocument, {isLoading, data, error, errors}] = useMutation("user/document-upload");

    useEffect(() => {
        if (invalidFileType) {
            setBackgroundImageDisplay("");
            setInvalidFileType(true);
        }
    }, [invalidFileType]);

    const handleBackgroundImageDrop = async (value: any) => {
        if (!value[0]) {
            setInvalidFileType(true);
            return;
        }

        setInvalidFileType(false);
        const result: any | null = await convertBase64(value[0]);
        setBackgroundImageDisplay(value[0]);
        setBackgroundImage(result);
    };

    const handleSubmitReviewButton = (event: any) => {
        event.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        uploadDocument({
            file: backgroundImage,
            extra: {
                name, amount
            }
        });
    };

    useEffect(() => {
        if (error) {
            toast(error?.title || 'Something Went Wrong', {
                type: toast.TYPE.ERROR,
            })
            setSuccessMessage('');
            setErrorMessage(error?.title || 'Something Went Wrong')
        }

        if (errors && errors?.length > 0) {
            let messages: string = "";
            errors?.forEach((error) => {
                messages += error?.title || 'Something Went Wrong';
                messages +=  "<br/>";
                toast(error?.title || 'Something Went Wrong', {
                    type: toast.TYPE.ERROR,
                })
            })
            setSuccessMessage('');
            setErrorMessage(messages)
        }
    }, [error, errors])
    useEffect(() => {
        if (data) {
            toast("File uploaded successfully!", {
                type: "success",
            });
            setBackgroundImageDisplay(null);
            setBackgroundImage(null);
            setSuccessMessage("File uploaded successfully!");
            setErrorMessage('')
        }
    }, [data])
    return (
        <>
            <Modal className="modalbox-s p-4 fileupload-modal" show={show} onHide={handleClose} centered={true}>
                <Modal.Header className={"text-center justify-content-center"} closeButton>
                    <h2>Upload Document</h2>
                </Modal.Header>
                <Modal.Body className={"modalbody mx-3"}>
                    <div className="w-100">
                        <div className="d-flex">
                            <input type="text" onChange={e => setName(e.target.value)} value={name} placeholder={'Name'}
                                   className={'form-control'}/>
                            <input type="number" onChange={e => setAmount(e.target.value)} value={amount}
                                   placeholder={'Amount CORE'} className={'form-control ml-2'}/>
                        </div>
                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                return handleBackgroundImageDrop(acceptedFiles);
                            }}
                            accept="image/png, image/jpeg, application/pdf"
                        >
                            {({getRootProps, getInputProps}) => (
                                <div
                                    className="wallets__file-upload d-flex justiy-content-center align-items-center my-4" {...getRootProps()}>
                                    <form className="w-100 ">
                                        <div className="wallets__file-upload-text text-center w-100">
                                            <p className="w-100">
                                                <span className="mr-2">Click here to drop files to upload</span>
                                                <i className="fa fa-upload" aria-hidden="true"></i>
                                            </p>
                                            <div className="photoUpload-files fl-wrap">
                                                {backgroundImageDisplay?.name && <p>{backgroundImageDisplay?.name}</p>}
                                                {invalidFileType &&
                                                    <span className="text-danger">Please upload JPEG, PNG or PDF file only!</span>}
                                            </div>
                                        </div>
                                        <input {...getInputProps()} />
                                    </form>
                                </div>
                            )}
                        </Dropzone>

                        {successMessage && (
                            <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>
                        )}

                        {errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}

                        {backgroundImage && !!amount && name !== '' && !invalidFileType && (
                            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                                <Button disabled={isLoading} variant={"secondary"} className={"mt-2 mr-sm-2 mb-0"}
                                        onClick={(event) => handleSubmitReviewButton(event)}>
                                    {isLoading ? <Spinner animation={'border'}/> : 'Submit'}
                                </Button>

                                <Button
                                    variant={"secondary"}
                                    className={"mt-2 mb-0"}
                                    onClick={() => {
                                        setBackgroundImage(null);
                                        setBackgroundImageDisplay(null);
                                        setAmount('');
                                        setName('');
                                    }}
                                >
                                    Clear
                                </Button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer className={"d-flex justify-content-center align-items-center"}>
                    <h2>Footer</h2>
                </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default FileUploadModal;
