export const PRIVATE_ROUTE_PARENT = "/dashboard";
export const TRANSACTION_LIST = "/transaction-list";
export const UNAUTHORISED_REDIRECT_ROUTE = "/";
export const AUTHORISED_REDIRECT_ROUTE = PRIVATE_ROUTE_PARENT;
export const UNVERIFIED_REDIRECT_ROUTE = "/";
export const AUTH_ROUTES = ["/sign-in", "/sign-up", "/forgot-password"];

export const FORGET_PASSWORD_ROUTE = "/forget-password";
// export const RESET_PASSWORD_ROUTE = "/password/reset";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const HOME_ROUTE = "/";
export const TERMS_ROUTE = "/terms";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const DASHBOARD = "/dashboard";
export const REFER_FRIENDS = "/refer";
export const FAQ = "/faqs";
export const TRAINING = "/training";
export const LEADERBOARD = "/leaderboard";
