import { RootState } from "../../";

export const selectIsUserLoggedIn = (state: RootState) =>
  !!state.userState?.user?.email;

export const selectIsUserRole = (state: RootState) =>
  !!state.userState?.user;

export const selectIsDentist = (state: RootState) =>
  !!state.userState?.user;

export const selectIsVerifiedIn = (state: RootState) =>
  !!state.userState?.user?.email_verified_at;

export const selectIsUserLoading = (state: RootState) =>
  !!state.userState?.isLoading;

export const selectIsUserFetched = (state: RootState) =>
  !!state.userState?.fetched;

export const selectUser = (state: RootState) => state.userState?.user;
