import React from "react";
import './style.scss';
import '../../components/WalletCard/styles.scss';

const TransactionCard= (props: any) => {
    return(
        <div>
            <div className={props.activeCard === props.coin ?  `wallet-card-container w-90 my-4` :
                `wallet-card-container w-90 my-4 transaction-card-overlay`}
                 onClick={() => props.handleActiveCard(props.coin)}
                style={{background: `linear-gradient(to right, ${props.coin.leftColor} 0%, ${props.coin.rightColor} 90%`,}}>
                <div className="wallet-card tr-card">
                    <div className="d-flex justify-content-between">
                        <h4>Transaction #{props.coin.id}</h4>
                        <img src={props.coin.TrImg} />
                    </div>
                    <div className="d-flex align-items-center wallet-card__content">
                        <img
                            className="mr-4 wallet-card__coin-image coin-image"
                            src={props.coin.coinImage}
                            alt=""
                        />
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="wallet-card__coin card-name">{props.coin.coinName}</span>
                                <span className="wallet-card__price last-price">{props.coin.price}</span>
                            </div>
                            <div>
                                <span className="wallet-card__last-price price">${props.coin.lastPrice}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionCard;
