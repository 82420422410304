import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import "./styles.scss";
import { useInjectReducer } from "../../utils/redux-injectors";
import { userReducer, userSliceKey } from "../../types/coreStates/user/slice";
import { useSelector } from "react-redux";
import { selectUser } from "../../types/coreStates/user/selectors";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ReferFriends = (props: { show: boolean; onHide: any; dex?: boolean; }) => {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  const user = useSelector(selectUser);
  const [copied, setCopied] = useState(false);
  let referral_link = user?.referral_link;
  if(props?.dex && user?.my_sst_address) referral_link = 'https://www.socialswap.io/referral/' + user?.my_sst_address
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="refer-friends text-center"
      style={{ paddingRight: "0" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="refer-friends__heading">Refer Friends</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        {
          props?.dex && <p className="refer-friends__paragraph mb-5">
            Invite a friend and get 10% of the reward they receive on Staking
          </p>
        }
        {
          !props?.dex && <p className="refer-friends__paragraph mb-4">
            Invite a friend and get 3% of the amount that you friend is buying CORE Token to your wallet.
          </p>
        }

        <div className="refer-friends__link-container text-white">
          <p>Your Referral-Link:</p>
          <input
            disabled
            type="text"
            placeholder="socialswapref123.io"
            value={referral_link}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <div className="w-100 d-flex justify-content-center align-items-center mb-3">
          <CopyToClipboard text={referral_link || ""} onCopy={() => {
            setCopied(true)
            setTimeout(() => setCopied(false), 1000)
          }}>
            <Button variant={'secondary'} disabled={copied}>
              {copied ? 'Copied': 'Copy Link'}
            </Button>
          </CopyToClipboard>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferFriends;
