import React from 'react';
import faqs from "../../assets/faqs.json";
import "./styles.scss"

const Faqs = () => {
    return (
        <div className="faqs container">
            <h1 className="text-center faqs__heading">Pre-Sale FAQ</h1>
            {
                faqs.map(faq => (
                    <Faq question={faq.question} answer={faq.answer} />
                ))
            }
            <h4 className="text-center mt-4 faqs__further-questions">
                
                more questions coming soon…

                <br />
                <br />

                If there are any unanswered questions, just text them privately on Telegram <a href="https://t.me/socialswapdefi">@corexsocialswap</a> and we are happy to include the most important ones to this FAQ for the whole community!
            </h4>
        </div>
    )
}

interface ownProps {
    question: any;
    answer: any
}

const Faq = (props: ownProps) => {
    return (
        <div className="mt-4">
            <h4 className="faqs__question">{props.question}</h4>
            <p className="faqs__answer">{props.answer}</p>
        </div>
    )
}

export default Faqs;
