import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { RouteInterface, ROUTES } from "../routes";
import {
  selectIsUserFetched,
  selectIsUserLoading,
  selectIsUserLoggedIn,
  selectIsVerifiedIn,
} from "../../types/coreStates/user/selectors";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  UNAUTHORISED_REDIRECT_ROUTE,
  UNVERIFIED_REDIRECT_ROUTE,
} from "../constants";
import Bg from "../../assets/images/home/homebg.png";
import { Container } from "react-bootstrap";
import { LoadingContainer } from "../../app/components/Atoms/LoadingContainer";
import Header from "../../components/Header";

export function PrivateRoute(props: RouteComponentProps) {
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const isVerified = useSelector(selectIsVerifiedIn);
  const isLoading = useSelector(selectIsUserLoading);
  const isFetched = useSelector(selectIsUserFetched);
  const location = useLocation().pathname;
  return (
    <div>
      <Switch>
        {getRoutes(
          ROUTES,
          isLoading,
          isLoggedIn,
          isFetched,
          isVerified,
          location
        )}
      </Switch>
    </div>
  );
}

const Comp = ({ com }: any) => {
  return (
    <React.Fragment>
      <Header />
      <main className="pt-3" >
        <Container>{com}</Container>
      </main>
    </React.Fragment>
  );
};

const Loading = (isLoading: boolean) => {
  return (
    <div
      className={"container-fluid"}
      style={{
        height: "100vh",
      }}
    >
      <div
        className={
          "h-100 w-100 d-flex justify-content-center align-items-center"
        }
      >
        <LoadingContainer isLoading={isLoading}>{""}</LoadingContainer>
      </div>
    </div>
  );
};

const getRoutes = (
  routes: RouteInterface[],
  isLoading: boolean,
  isLoggedIn: boolean,
  isFetched: boolean,
  isVerified: boolean,
  location: string
) => {
  return routes.map((prop, key) => {
    if (prop.isPrivate) {
      return (
        <PrivateRouter
          {...prop}
          isLoggedIn={isLoggedIn}
          isLoading={isLoading}
          isVerified={isVerified}
          isFetched={isFetched}
          path={prop.parent + prop.path}
          component={prop.component}
          key={key}
          location={location}
          redirect_to={prop.redirect_to}
        />
      );
    } else {
      return null;
    }
  });
};

function PrivateRouter({
  component: Component,
  isLoading,
  isLoggedIn,
  isFetched,
  isVerified,
  location,
  redirect_to,
  ...rest
}: any) {
  const history = useHistory();
  useEffect(() => {
    if (
      !isLoading &&
      isLoggedIn &&
      !isVerified &&
      location !== UNVERIFIED_REDIRECT_ROUTE
    ) {
      history.push(UNVERIFIED_REDIRECT_ROUTE);
    }
  }, [isLoading, isLoggedIn, isVerified, location]);

  useEffect(() => {
    if (!isLoading && !isLoggedIn && isFetched) {
      history.push(UNAUTHORISED_REDIRECT_ROUTE);
    }
  }, [isLoggedIn, isLoading, isFetched]);

  if (isLoggedIn) {
    if (redirect_to) {
      return (
        <Route>
          <Redirect {...rest} to={redirect_to} />
        </Route>
      );
    }
    if (process?.env?.NODE_ENV !== "development" && rest?.isDevelopment) {
      console.log(`Process: `, process.env.NODE_ENV);
      return <div></div>;
    }
    return (
      <Route
        {...rest}
        render={(props) => <Comp com={<Component {...props} />} />}
      />
    );
  } else {
    return <Route render={() => Loading(isLoading)} />;
  }
}

// export const PrivateRoute = connect(null, null)(PrivateRouteComponent as any);
