import React from "react";
import "./style.scss";
import { Row } from "react-bootstrap";

const TokenSold = ({
  total_sold,
  remaining,
}: {
  total_sold: string;
  remaining: string;
}) => {
  const total = parseFloat(total_sold || "0") + parseFloat(remaining || "0");
  const percentage =
    ((parseFloat(total_sold || "0") * 100) / total);

  return (
    <>
      <div className="token-box my-5">
        <h1 className="text-center font-weight-bold mb-2">Tokens Available</h1>
        <div className={'row p-0'}>
          <div className="d-flex justify-content-between align-items-center col-12 col-md-9 col-lg-7 mx-auto mb-2 flex-column flex-md-row">
            <p className="font-weight-bold text-center token-sold-title text-danger text-bold mb-0">
              {percentage?.toFixed(4)}% Tokens Sold
              {/*{parseFloat(total_sold || "0")} Tokens Already Sold*/}
            </p>
            <p className="font-weight-bold  token-sold-title text-danger text-bold mb-0">
              {" "}
              {/*{parseFloat(remaining || "0")} Left*/}
            </p>
          </div>
        </div>
        <Row className={"p-0"}>
          <div className="col-12 col-md-9 col-lg-7 mx-auto">
            <div className="token-bar">
              <div className="token-path d-flex justify-content-between align-items-center">
                <div
                  style={{ width: `${percentage}%` }}
                  className="token-track"
                >
                  {/*{parseFloat(total_sold || "0") > 0 && <h3 className="text-nowrap font-weight-bold text-center token-sold-title">*/}
                  {/*  {parseFloat(total_sold || "0")} Tokens Already Sold*/}
                  {/*</h3>}*/}
                </div>
                {/*{parseFloat(remaining || "0") > 0 && <h3 className="text-nowrap font-weight-bold  token-sold-title">*/}
                {/*  {parseFloat(remaining || "0")} Left*/}
                {/*</h3>}*/}
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default TokenSold;
