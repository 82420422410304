import React, {useMemo} from "react";
import './style.scss'
import WalletsTimer from "../../components/WalletsTimer";
import {Button, Row, Accordion, Card} from "react-bootstrap";
import BTC from "../../assets/images/coins/btc.svg";
import ETH from "../../assets/images/coins/ETH.svg";
import USDT from "../../assets/images/coins/USDT.svg";
import Rocket from "../../assets/images/home/rocket.svg";
import PreSale from "../../components/PreSale/PreSale";
import youtube from "../../assets/images/home/youtube.svg";
import telegram from "../../assets/images/home/telegram.svg";
import infra from "../../assets/images/home/infra.svg";
import reddit from "../../assets/images/home/reddit.svg";
import {useQuery} from "jsonapi-react";
import {LoadingContainer} from "../../app/components/Atoms/LoadingContainer";
import TokenSold from "../../components/TokenSold";
import {useInjectReducer} from "../../utils/redux-injectors";
import {userReducer, userSliceKey} from "../../types/coreStates/user/slice";
import {useSelector} from "react-redux";
import {selectIsUserLoggedIn} from "../../types/coreStates/user/selectors";
import SignUpModal from "../../components/SignUp";
import SignInModal from "../../components/SignIn";
import moment from "moment";
import logo from "./../../assets/images/home/socialswap-logo.png";
import icox from "./../../assets/images/home/icox.png";
import phone from "./../../assets/images/home/phone.svg";
import walletIcon from "./../../assets/images/home/wallet-icon.svg";
import responsiveIcon from "./../../assets/images/home/responsive-icon.svg";
import peopleIcon from "./../../assets/images/home/people-icon.svg";
import safeIcon from "./../../assets/images/home/safe-icon.svg";
import gradientTick from "./../../assets/images/home/gradient-tick.svg";
import gradientClock from "./../../assets/images/home/gradient-clock.svg";
import certified from "./../../assets/images/home/certified.png";
import certikLogo from "./../../assets/images/home/certik-logo.svg";
import probitLogo from "./../../assets/images/home/probit-logo.svg";
import bgContactBottom from "./../../assets/images/home/bg-contact-bottom.svg";
import bgHome0 from "./../../assets/images/home/bg-home-0.png";
import bgHome1 from "./../../assets/images/home/bg-home-1.png";
import bgHome2 from "./../../assets/images/home/bg-home-2.svg";
import bgHome3 from "./../../assets/images/home/bg-home-3.svg";
import bgHome4 from "./../../assets/images/home/bg-home-4.svg";
import bgHome5 from "./../../assets/images/home/bg-home-5.svg";
import pie from "./../../assets/images/home/pie.png";
import arrowDown from "./../../assets/images/home/arrow-down.png";

const Home = () => {
    useInjectReducer({ key: userSliceKey, reducer: userReducer });
    const isLoggedIn = useSelector(selectIsUserLoggedIn);
    const [signUpModalShow, setSignUpModalShow] = React.useState(false);
    const [signInModalShow, setSignInModalShow] = React.useState(false);

    const {data: icosData, isLoading: icosLoading} = useQuery(['public/icos', {
        filter: {
            // currency: 'core'
        },
        include: [
            'next_stage',
            'ico_stages',
            'current_stage',
            'currency',
            'payment_currencies',
        ]
    }]);


    const ico = useMemo(() => {
        try {
            return !(icosData && icosData?.length > 0) || icosData[0] || []
        } catch (e) {
            return [];
        }
    }, [icosData]);

    const token_available = ico?.ico_stages?.reduce((a: string, b: { hard_cap: string; total_sold: string; end_date: string; }) => {
        if(parseFloat(b?.total_sold) >= parseFloat(b?.hard_cap) || moment(b?.end_date).diff(moment()) <= 0) return a + 0;
        return parseFloat(a) + parseFloat(b.hard_cap);
    }, 0)
    return(
        <>
            <LoadingContainer isLoading={icosLoading}>
                <section id="hero" style={{ backgroundImage: `url(${bgHome0})` }}>
                    <div className="heading mt-5">

                        <div className="row pb-0">
                            <div className="col text-center">
                                <img className="header__logo mb-5 mt-5 d-none d-sm-inline" src={logo} alt="Socialswap &amp; CORE Logo" />
                                <h1 className="mb-5 mt-2">A Resourceful Utility Token</h1>
                                <a className="btn btn-secondary btn-sm mb-5" href="https://coretoken.net" target="_blank">More Information</a>
                                <p className="subheading text-center text-white">Exchange value or pay for opertional services</p>
                            </div>
                        </div>

                    </div>
                    {/*{ico && ico?.current_stage && <React.Fragment>*/}
                    {/*    <TokenSold total_sold={ico?.current_stage?.total_sold} remaining={ico?.current_stage?.remaining}/>*/}
                    {/*</React.Fragment>}*/}
                    <React.Fragment>
                        <WalletsTimer date={"2021-03-28T13:00:00.000000Z"} stage={''}/>
                    </React.Fragment>
                    {!isLoggedIn && <div className="text-center">
                        {/* <Button className={'mt-3'} onClick={() => setSignUpModalShow(true)} variant={'secondary'} size={'lg'}>Sign Up Now</Button> */}
                        {/*TODO: Move this Reducer*/}
                        <SignUpModal         show={signUpModalShow}
                                             onHide={() => setSignUpModalShow(false)}
                                             onSignInClick={() => {
                                                 setSignUpModalShow(false);
                                                 setSignInModalShow(true);
                                             }} />
                    </div>}
                    {/*TODO: Move this Reducer*/}
                    {signInModalShow && (
                        <SignInModal
                            show={signInModalShow}
                            showRegisterModal={() => setSignUpModalShow(true)}
                            onHide={() => setSignInModalShow(false)}
                            onSignUpClick={() => {
                                setSignInModalShow(false);
                                setSignUpModalShow(true);
                            }}
                            isLoggedIn={isLoggedIn}
                        />
                    )}
                    <Row>
                        <div className="col-12 col-md-6 col-lg-6 d-none">
                            <div className="coin-conatainer">
                                <div className="coinbox">
                                    <div className="coinbox_body">
                                        <h2>Swap to BTC/ETH/TRX on TRC-20</h2>
                                        <Row>
                                            {ico && ico?.payment_currencies && ico?.payment_currencies.slice(0,3).map((payment_currency: any) => (
                                                <div className="col-4"><img src={payment_currency.currency_details?.icon_url} alt={payment_currency.currency_details?.id} /><p> {payment_currency.currency_details?.name}</p></div>
                                            ))}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 d-none">
                            <div className="token"><h2>1,000,000,000</h2><p>Max Supply</p></div>
                            {/* @ts-ignore */}
                        {/*    <div className="token"><h2>{Number(token_available).toMoney()} of 34,000,000</h2><p>Pre-Sale Token Available</p></div>*/}
                        {/*</div>*/}
                            {/* <div className="token"><h2>0</h2><p>Pre-Sale Token Available</p></div> */}
                            </div>
                        {/*<div className="col-12 col-lg-3 text-center">*/}
                        {/*    <div className="d-flex justify-content-center align-items-center">*/}
                        {/*        <img src={Rocket} alt="rocket"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Row>
                    {/*<PreSale ico={ico} />*/}
                </section>
            </LoadingContainer>

            {/* Footer */}
            <Row>
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: "100px" }} className="col-12 mx-auto">
                            <Row className="pb-0 row">
                                <div className="col-12 col-lg-8">
                                    <h2 className="mt-3 font-weight-bold home__follow-text">
                                        Follow us and get the latest updates on SocialSwap.
                                    </h2>
                                </div>
                                <div className="col-12 col-lg-4 text-center social-icons">
                                    <div
                                        className={
                                            "d-flex justify-content-center align-items-center flex-column"
                                        }
                                    >
                                        <div className="d-flex justify-content-center align-items-center pb-1">
                                            <a
                                                target={"_blank"}
                                                href="https://www.youtube.com/channel/UC6gcqlRfsUuW_ZJ1BDVfycQ"
                                            >
                                                <img className="social-icon" src={youtube} />
                                            </a>
                                            <a
                                                target={"_blank"}
                                                href="https://www.reddit.com/r/SocialSwap/"
                                            >
                                                <img className="social-icon" src={reddit} />
                                            </a>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <a target={"_blank"} href="https://t.me/socialswapio">
                                                    <img className="social-icon pb-0" src={telegram} />
                                                </a>
                                                <span>Chat</span>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <a target={"_blank"} href="https://t.me/socialswapchannel">
                                                    <img className="social-icon pb-0" src={telegram} />
                                                </a>
                                                <span>Channel</span>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <a target={"_blank"} href="https://t.me/corexsocialswap">
                                                    <img className="social-icon pb-0" src={telegram} />
                                                </a>
                                                <span className="ml-2">Support</span>
                                            </div>
                                        </div>
                                        {/* <a
                                            target={"_blank"}
                                            href="/Whitepaper.pdf"
                                            className="mx-auto btn btn-secondary"
                                        >
                                            White Paper
                                        </a> */}
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </Row>

        </>
    )
}

export default Home;