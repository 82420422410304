import React, {useEffect, useState} from "react";
import './style.scss';
import {Modal, Spinner} from "react-bootstrap";
import {useMutation, useQuery} from "jsonapi-react";
import { toast } from 'react-toastify';
import {useInjectReducer} from "../../utils/redux-injectors";
import {userActions, userReducer, userSliceKey} from "../../types/coreStates/user/slice";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../types/coreStates/user/selectors";
import {handleJsonApiError, handleJsonApiErrors} from "../../helpers/handleErrors";

const TradeModal = ({show, handleClose, ico}: {ico: any; show: boolean; handleClose: (success?: boolean) => void }) => {
    const [amount, setAmount] = useState<undefined|number>();
    const [total, setTotal] = useState<undefined|number>();
    const [price, setPrice] = useState<undefined|number|string>();
    const [p_currency, setPCurrency] = useState<undefined|string>();
    useInjectReducer({ key: userSliceKey, reducer: userReducer });
    const user = useSelector(selectUser);
    const base_rate = ico.current_stage.base_price;
    const selected_currency = ico.payment_currencies.find((p_c: { id: string | undefined; }) => p_c.id === p_currency)

    const dispatch = useDispatch();
    const [address, setAddress] = useState<undefined | string>(user?.wallet_address);
    const [submitTransaction, {isLoading, data, error, errors}] = useMutation(['ico_transactions', {
    }]);
    const { data: accountsData, isLoading: accountssLoading } = useQuery([
        "accounts", {}
    ]);
    useEffect(() => {
        if(!show) {
            setAmount(0)
            setTotal(0)
        }
    }, [show])
    useEffect(() => {
        if(selected_currency) {
            if(ico.config.base_conversion_currency.toLowerCase() === selected_currency.currency_details.id.toLowerCase()) {
                setPrice(base_rate)
            } else {
                setPrice(Number(base_rate / selected_currency?.provider_rate).toFixed(8))
            }
            setAmount(0)
            setTotal(0)
        }
    }, [selected_currency])

    useEffect(() => {
        if(error) handleJsonApiError(error)
        if(errors && errors?.length > 0) handleJsonApiErrors(errors)
    }, [error, errors])
    useEffect(() => {
        if(data) {
            toast("Order Submitted, You will receive an email when order is executed.", {
                type: toast.TYPE.SUCCESS,
            });
            handleClose(true);
        }
    }, [data]);
    const account = accountsData?.find((ac: { currency_id: any; balance: any; }) => ac.currency_id === selected_currency?.currency_details?.id)
    return (
        <>
            <Modal className="modalbox-s" show={show} onHide={handleClose} centered={true}>
                <Modal.Header className={'text-center justify-content-center'} closeButton>
                    <h2 className="text-center w-100">Trade To CORE</h2>
                </Modal.Header>
                <Modal.Body className={'modalbody p-2 text-dark'}>
                    <div className="container-fluid">
                        <p className={'m-0 p-0'}>
                            <span>Trade Your Funds to CORE</span>
                        </p>
                        <div className="form">
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label className={'text-left'} htmlFor="amount">Amount CORE</label>
                                    <input value={amount} onChange={e => {
                                        setAmount(Number(e.target.value))
                                        const value = parseFloat(e.target.value);
                                        if(value && selected_currency) {
                                            if(ico.config.base_conversion_currency.toLowerCase() === selected_currency.currency_details.id.toLowerCase()) {
                                                setTotal(Number((value * (base_rate)).toFixed(8)))
                                            } else {
                                                setTotal(Number((value * (base_rate / selected_currency?.provider_rate)).toFixed(8)))
                                            }
                                        } else {
                                            setTotal(0)
                                        }
                                    }} type="text" className="form-control" placeholder={`${ico?.currency?.id?.toUpperCase()} Amount`}/>
                                </div>
                                <div className="col-md-6  mb-2">
                                    <label className={'text-left'} htmlFor="price">Price CORE</label>
                                    <input disabled={true} type="text" value={price} className="form-control" placeholder={`Price ${selected_currency?.currency_details?.id?.toUpperCase() || ''}`}/>
                                </div>

                                <div className="col-md-6  mb-2">
                                    <label className={'text-left'} htmlFor="total">Total</label>
                                    <input disabled={true} type="text" value={total} className="form-control" placeholder={`Total ${selected_currency?.currency_details?.id?.toUpperCase() || ''}`}/>
                                    <a onClick={() => {
                                        if(account) {
                                            const balance = parseFloat(Number(account.balance).toFixed(8) || '0');
                                            let price = 0;
                                            if(ico.config.base_conversion_currency.toLowerCase() === selected_currency.currency_details.id.toLowerCase()) {
                                                price = parseFloat(base_rate)
                                            } else {
                                                price = parseFloat(Number(base_rate / selected_currency?.provider_rate).toFixed(8))
                                            }

                                            // const rate = parseFloat(price || '0');
                                            // const rate = parseFloat(selected_currency?.provider_rate || '0');
                                            if(balance <= 0) return;
                                            if(!selected_currency || price <= 0) return;
                                            // let amount = Number((balance / price)) || 0;
                                            // console.log('amount * price > balance', amount * price , balance, balance / price)
                                            // if(amount * price > balance) {
                                            //     // const amount_2 = (amount * price) - balance;
                                            //     amount = balance;
                                            // }
                                            setTotal(balance)
                                            if(balance && balance > 0) {
                                                if(ico.config.base_conversion_currency.toLowerCase() === selected_currency.currency_details.id.toLowerCase()) {
                                                    setAmount(Number((balance / (base_rate)).toFixed(8)))
                                                } else {
                                                    setAmount(Number((balance / (base_rate / selected_currency?.provider_rate)).toFixed(8)))
                                                }
                                            } else {
                                                setAmount(0)
                                            }
                                        }
                                    }} className="text-danger font-weight-bold">Max.</a>
                                </div>
                                <div className="col-md-6  mb-2">
                                    <label htmlFor="currency">Currency</label>
                                    <select onChange={e => setPCurrency(e.target.value)} className="form-control">
                                        <option value="">Select Currency</option>
                                        {ico.payment_currencies.map((payment_currency: { id: string | number | readonly string[] | undefined; currency_details: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; }) => {
                                            if(payment_currency.id && payment_currency?.currency_details?.name) {
                                                return (<option value={payment_currency.id}>{payment_currency?.currency_details?.name}</option>);
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-center align-items-center'}>
                    <div>
                        <a onClick={(e) => {
                            e.preventDefault();
                            if(isLoading) return;
                            submitTransaction({
                                ico_stage: {
                                    id: ico.current_stage.id,
                                },
                                payment_currency: {
                                    id: p_currency,
                                },
                                amount: amount?.toFixed(8)
                            })
                        }}  className="btn btn-secondary">
                            {isLoading ? <Spinner animation={'border'} /> : 'Trade' }
                        </a>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TradeModal;
