import React, {useMemo} from "react";
// import "./styles.scss";
import logo from "./../../assets/images/home/socialswap-logo.png";
import MenuIcon from "../../assets/images/header/transaction-list.svg";
import SignUpModal from "../SignUp";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectIsUserLoggedIn, selectIsVerifiedIn } from "../../types/coreStates/user/selectors";
import SignInModal from "../SignIn";
import { Link } from "react-router-dom";
import {
    DASHBOARD,
    FAQ,
    LEADERBOARD,
    PRIVATE_ROUTE_PARENT,
    REFER_FRIENDS,
    TRAINING,
    TRANSACTION_LIST
} from "../../routes/constants";
import { userActions, userReducer, userSliceKey } from "../../types/coreStates/user/slice";
import { useInjectReducer, useInjectSaga } from "../../utils/redux-injectors";
import { userSaga } from "../../types/coreStates/user/saga";
import VerifyAccount from "../VerifyAccount";
import { useQuery } from "jsonapi-react";
import ForgotPassword from "../ForgotPassword";
import { selectIsModalOpen } from "../../types/coreStates/toupWallet/selectors";
import { topupActions, topupReducer, topupSliceKey } from "../../types/coreStates/toupWallet/slice";
import { toast } from "react-toastify";

const Header = () => {
    const { data: icosData, isLoading: icosLoading } = useQuery([
        "public/icos",
        {
            filter: {
                // currency: "core",
            },
            include: ["next_stage", "current_stage", "currency", "payment_currencies"],
        },
    ]);
    const ico = useMemo(() => {
        try {
            return !(icosData && icosData?.length > 0) || icosData[0] || []
        } catch (e) {
            return [];
        }
    }, [icosData]);
    useInjectReducer({ key: userSliceKey, reducer: userReducer });
    useInjectReducer({ key: topupSliceKey, reducer: topupReducer });
    useInjectSaga({ key: userSliceKey, saga: userSaga });
    const [signUpModalShow, setSignUpModalShow] = React.useState(false);
    const [signInModalShow, setSignInModalShow] = React.useState(false);
    const [verifyModalShow, setVerifyModalShow] = React.useState(false);
    const setTopUpModalShow = (open: boolean) => {
        dispatch(topupActions.openModal({}));
    };

    // const [referFriendsModalShow, setReferFriendsModalShow] = React.useState(
    //   false
    // );
    const loggedIn = useSelector(selectIsUserLoggedIn);
    const isVerified = useSelector(selectIsVerifiedIn);
    const isLoggedIn = loggedIn && isVerified;
    const dispatch = useDispatch();
    return (
        <header className="header">
            <Navbar expand="lg" variant={'dark'}>
                <Navbar.Brand href="/" className={"px-5"}>
                    <img className="header__logo" src={logo} alt="Socialswap &amp; CORE Logo" />
                </Navbar.Brand>
                {(ico?.current_stage?.base_price || ico?.next_stage?.base_price) && (
                    <Nav.Link onClick={() => {}} className={"mobile-price"}>
                        CORE ${parseFloat(ico?.current_stage?.base_price || ico?.next_stage?.base_price || 0)}
                    </Nav.Link>
                )}
                 <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav className="">
                        {isLoggedIn && (
                            <Link to={DASHBOARD} className="btn btn-secondary bg mr-2">
                                Dashboard
                            </Link>
                        )}
                        {isLoggedIn && (
                            <div>
                                <Link to={TRAINING} className={"nav-link"}>
                                    Training
                                </Link>
                            </div>
                        )}
                        {isLoggedIn && (
                            <div>
                                <Link to={PRIVATE_ROUTE_PARENT + LEADERBOARD} className={"nav-link"}>
                                    Leaderboard
                                </Link>
                            </div>
                        )}
                        {isLoggedIn && (
                            <div>
                                <Link to={PRIVATE_ROUTE_PARENT + REFER_FRIENDS} className={"nav-link"}>
                                    Refer friends
                                </Link>
                            </div>
                        )}
                        {isLoggedIn && (
                            <Nav.Link onClick={() => setTopUpModalShow(true)}>
                            {/*<Nav.Link*/}
                            {/*    onClick={() =>*/}
                            {/*        toast("Top-Up Disabled", {*/}
                            {/*            type: toast.TYPE.ERROR,*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*>*/}
                                Top-Up Wallet
                            </Nav.Link>
                        )}
                        {/*{isLoggedIn && (*/}
                        {/*    <div>*/}
                        {/*      <Link to={TRANSACTION_LIST} className={"nav-link"}>*/}
                        {/*        <img style={{height: "30px"}} src={MenuIcon} />*/}
                        {/*      </Link>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {!loggedIn && <Nav.Link onClick={() => setSignUpModalShow(true)}>Sign Up</Nav.Link>}
                        {!loggedIn && <Nav.Link onClick={() => setSignInModalShow(true)}>Sign In</Nav.Link>}
                        {loggedIn && !isLoggedIn && <Nav.Link onClick={() => setVerifyModalShow(true)}>Verify Your Account</Nav.Link>}
                        <div>
                            <Link to={FAQ} className={"nav-link"}>
                                FAQ
                            </Link>
                        </div>
                         {loggedIn && <Nav.Link onClick={() => dispatch(userActions.logoutFetchAction())}>Logout</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <SignUpModal
                show={signUpModalShow}
                onHide={() => setSignUpModalShow(false)}
                onSignInClick={() => {
                    setSignUpModalShow(false);
                    setSignInModalShow(true);
                }}
            />
            {signInModalShow && (
                <SignInModal
                    show={signInModalShow}
                    showRegisterModal={() => setSignUpModalShow(true)}
                    onHide={() => setSignInModalShow(false)}
                    onSignUpClick={() => {
                        setSignInModalShow(false);
                        setSignUpModalShow(true);
                    }}
                    isLoggedIn={isLoggedIn}
                />
            )}
            {/*<ReferFriends*/}
            {/*  show={referFriendsModalShow}*/}
            {/*  onHide={() => setReferFriendsModalShow(false)}*/}
            {/*/>*/}
            <VerifyAccount show={verifyModalShow} handleClose={() => setVerifyModalShow(false)} />
        </header>
    );
};

{
    /* <header class="header">
  <div class="overlay has-fade"></div>

  <nav class="container flex flex-jc-sb flex-ai-c">
    <a href="/" class="header__logo">
      <img src="./images/logo.svg" alt="Easybank" />
    </a>

    <a id="btnHamburger"  class="header__toggle hide-for-desktop">
      <span></span>
      <span></span>
      <span></span>
    </a>

    <div class="header__links hide-for-mobile">
      <a  class="link">Home</a>
      <a  class="link">About</a>
      <a  class="link">Contact</a>
      <a  class="link">Blog</a>
      <a  class="link">Careers</a>
    </div>

    <a  class="button header__cta hide-for-mobile"
      >Request Invite</a
    >
  </nav>

  <div class="header__menu has-fade">
    <a  class="link">Home</a>
    <a  class="link">About</a>
    <a  class="link">Contact</a>
    <a  class="link">Blog</a>
    <a  class="link">Careers</a>
  </div>
  </header> */
}

export default Header;
