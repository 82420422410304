import { Spinner } from "react-bootstrap";
import React from "react";

export function LoadingContainer({children, isLoading, dependsOnLoading = undefined}: any) {
  if (isLoading && !dependsOnLoading) {
    return (
      <div className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else if (isLoading && dependsOnLoading) {
    return null;
  }
  return children;
}
