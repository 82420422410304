import React, {useCallback, useEffect, useState} from "react";
import "./styles.scss";
import {LoadingContainer} from "../../app/components/Atoms/LoadingContainer";
import moment from "moment";
import {Button, Row} from "react-bootstrap";
import {API} from "../../api";
import {toast} from "react-toastify";

const Leaderboard = () => {
    const [leaderboards, setLeaderboards] = useState<any[]>([]);
    const [leaderboardLoading, setLeaderboardLoading] = useState(false);
    const fetchLeaderBoard = useCallback(async () => {
        try {
            const {data} = await API.get({
                apiVersion: "apiUrl",
            })('/global');
            setLeaderboards(data?.data);
        } catch (e) {
            toast('Failed to load leaderboard', {
                type: 'error'
            })
        }
        setLeaderboardLoading(false)
    }, []);
    useEffect(() => {
        setLeaderboardLoading(true)
        fetchLeaderBoard()
    }, [fetchLeaderBoard])
  return (
    <div className="training">
        <div className="row p-lg-3 my-2 video-width mx-auto mb-4">
            <div className="col-lg-12 px-0 px-0">
                <h1 className="font-weight-bold heading text-center text-white">Leaderboard</h1>
            </div>
        </div>
        <Row className="mt-5 text-dark">
            <div className="col-12 col-md-10 mx-auto">
                <div className="bgc">
                    <div className="table-responsive text-dark">
                        <table className="table text-dark text-left">
                            <thead>
                            <tr>
                                <th>Rank</th>
                                <th>User</th>
                                <th>Tokens Sold</th>
                            </tr>
                            </thead>
                            <tbody>
                            <LoadingContainer isLoading={leaderboardLoading}>
                                {leaderboards && leaderboards.map((user: any) => (
                                    <tr>
                                        <td>{user?.rank}</td>
                                        <td>{user?.user}</td>
                                        <td>{parseFloat(user?.total_sold).toFixed(4)} CORE</td>
                                    </tr>
                                ))}
                            </LoadingContainer>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    </div>
  );
};

export default Leaderboard;
