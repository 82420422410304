import React, { useState, useEffect } from "react";
import "./styles.scss";
import moment from 'moment';

import { useSelector } from "react-redux";
import { selectIsUserLoggedIn } from "../../types/coreStates/user/selectors";
import SignUpModal from "../SignUp";

const WalletsTimer = ({ date, stage = undefined }: any) => {
  const calculateTimeLeft = () => {
    const starts = moment(date);


    const now = moment();
    const difference = starts.diff(now);
    let timeLeft: any = {};

    if (starts.diff(now, 'seconds') > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());



  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });


  const [signUpModalShow, setSignUpModalShow] = React.useState(false);
  const loggedIn = useSelector(selectIsUserLoggedIn);

  if(!timeLeft?.seconds) {
    return (
        <div className="wallets-timer text-center">
          <h2 className="wallets-timer__text">
            {/* {stage?.name || "socialswap.io DEX"} Launched */}
          </h2>
          {!loggedIn && <button className="btn btn-secondary btn-sm" onClick={() => setSignUpModalShow(true)}>Join Token Sale</button>}

          <SignUpModal
            show={signUpModalShow}
            onHide={() => setSignUpModalShow(false)}
            onSignInClick={() => {
                setSignUpModalShow(false);
            }}
          />

        </div>
    );
  }

  return (
    <div className="wallets-timer text-center">
      <h2 className="wallets-timer__text">
        {stage?.name || "socialswap.io DEX"} Launches In
      </h2>
      <div className="wallets-timer__countdown mt-4">
        <div className="wallets-timer__days">
          <span>{timeLeft.days}</span>
          <span>Days</span>
        </div>

        <div className="wallets-timer__hours">
          <span>{timeLeft.hours}</span>
          <span>Hours</span>
        </div>

        <div className="wallets-timer__minutes">
          <span>{timeLeft.minutes}</span>
          <span>Minutes</span>
        </div>

        <div className="wallets-timer__seconds">
          <span>{timeLeft.seconds}</span>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default WalletsTimer;
