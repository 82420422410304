import { useState } from "react";
import {Modal, Button, Spinner} from "react-bootstrap";

import "./artboard4Styles.scss";
import Image from "../../assets/images/Artboard4/download.png";
import {useDispatch} from "react-redux";
import {API} from "../../api";
import {userActions} from "../../types/coreStates/user/slice";
import {handleApiError} from "../../helpers/handleErrors";
import {toast} from "react-toastify";

const VerifyAccount = ({show, handleClose}: any) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    if(loading) return;
    setLoading(true);
    API.post({
      apiVersion: 'apiUrl'
    })('/email/verification-notification', {})
        .then(() => {
          setLoading(false);
          toast('Verification Email Sent')
        })
        .catch((e) => {
          setLoading(false);
          handleApiError(e);
        })
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-verify" centered>
        <Modal.Body className="">
          <div className="title text-center my-5">
            <h1 className="font-weight-bold">Congratulations</h1>
          </div>
          <div className="d-flex justify-content-center align-items-center my-4">
            <img src={Image} className={'img-fluid'} alt={''} />
          </div>
          <div className="para text-center my-4">
            <p className="sentence">
              Please check your email inbox to verify your account.
            </p>
          </div>
          <div className="back-to-wallet text-center mt-5">
            <Button
              variant="light"
              size={'lg'}
              className=""
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? <Spinner animation={'border'} /> : 'Resend'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyAccount;
