import React, { useState } from "react";
import { Modal, Button, Navbar, Spinner } from "react-bootstrap";
import redIcon from "./../../assets/images/header/red-icon.png";
import blueIcon from "./../../assets/images/header/blue-icon.png";
import logo from "./../../assets/images/home/socialswap-logo.png";
import "./styles.scss";
import { useInjectReducer, useInjectSaga } from "../../utils/redux-injectors";
import {
  userActions,
  userReducer,
  userSliceKey,
} from "../../types/coreStates/user/slice";
import { userSaga } from "../../types/coreStates/user/saga";
import { useDispatch } from "react-redux";
import { API } from "../../api";
import { handleApiError } from "../../helpers/handleErrors";
import { toast } from "react-toastify";
import {Link} from "react-router-dom";
import {FAQ, PRIVATE_ROUTE_PARENT} from "../../routes/constants";
import Terms from "../Terms";

interface ownProps {
  coin: any;
}

const SignUpModal = (props: {
  show: boolean;
  onHide: any;
  onSignInClick: () => void;
}) => {
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: userSaga });
  const urlParams = new URLSearchParams(window.location.search);
  // @ts-ignore
  const ref: string | undefined = urlParams?.get("ref");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [Referral, setReferral] = useState<string | undefined>(ref);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [countryCode, setCountryCode] = useState<number|string>();
  const [phoneNumber, setPhoneNumber] = useState<number|string>();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const refId = window.location.search.split("=")[1];
  const dispatch = useDispatch();

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    if (password !== confirmPassword) {
      toast("Passwords don't match!", {
        type: 'error'
      });
      setLoading(false);
      return;
    }
    API.post({
      apiVersion: "apiUrl",
    })("/register", {
      name,
      email,
      username: username === '' ? undefined : username,
      password,
      password_confirmation: confirmPassword,
      referred_by: Referral,
      // country_code: countryCode,
      phone_number: phoneNumber,
    })
      .then(() => {
        toast("Verification Email Sent");
        dispatch(userActions.fetchUser(null));
        props.onHide();
        setLoading(false);
      })
      .catch((e) => {
        handleApiError(e);
        setLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="signup-modal text-center"
      style={{ paddingRight: "0" }}
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title
          className="justify-content-center align-items-center w-100"
          id="contained-modal-title-vcenter"
        >
          <div className="row text-center">
            <div className="col">
            <img
              style={{ maxWidth: "180px" }}
              className="img-fluid"
              src={logo}
              alt=""
            />
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <h4 className="signup-modal__label mb-0">Sign Up</h4>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="signup-modal__form" onSubmit={onSubmit}>
          <div>
            <input
              required
              type="text"
              autoComplete={'on'}
              name={'given-name'}
              placeholder="Name*"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div>
            <input
              required
              autoComplete={'on'}
              name={'email'}
              type="email"
              placeholder="Email*"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div>
            <input
              name={'username'}
              type="text"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
          </div>
          {/*<div>*/}
          {/*  <input*/}
          {/*    required*/}
          {/*    autoComplete={'on'}*/}
          {/*    name={'country_code'}*/}
          {/*    type="text"*/}
          {/*    placeholder="Country Code"*/}
          {/*    onChange={(e) => setCountryCode(Number(e.target.value || '') || undefined)}*/}
          {/*    value={countryCode}*/}
          {/*  />*/}
          {/*</div>*/}
          <div>
            <input
              required
              autoComplete={'on'}
              name={'phone_number'}
              type="text"
              placeholder="Phone Number"
              // onChange={(e) => setPhoneNumber(Number(e.target.value || '') || '')}
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
          </div>
          <div>
            <input
              required
              autoComplete={'on'}
              name={'new-password'}
              type="password"
              placeholder="Password*"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div>
            <input
              required
              autoComplete={'on'}
              name={'confirm-password'}
              type="password"
              placeholder="Confirm Password*"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </div>
          <div>
            <input
              required
              type="text"
              placeholder="Referral"
              onChange={(e) => setReferral(e.target.value)}
              value={Referral}
              disabled={!!refId}
            />
          </div>

        <div className="w-100 mt-4 d-flex justify-content-center align-items-center mb-3">
          <button className="sign-up-btn btn btn-secondary w-100 " onClick={onSubmit}>
            {loading ? <Spinner animation={"border"} /> : "Sign Up"}
          </button>
        </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <span className="signup-modal__terms">
          By continuing you agree to accept our <a href="/terms" target="_blank">Privacy Policy & Terms of Service</a>
        </span>
        <div className="w-100 mt-4 d-flex justify-content-center align-items-center">
          <a
            className="signup-modal__login-link"
            onClick={(e) => {
              e.preventDefault();
              props.onSignInClick();
            }}
          >
            Log In
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignUpModal;
