import { RequestOptions } from "./requestBuilder";

export interface Config {
  apiUrl: string;
  jsonApiUrl: string;
  sanctumUrl: string;
  authUrl: string;
}

export const defaultConfig: Config = {
  apiUrl: "/api",
  jsonApiUrl: "/api/v1",
  sanctumUrl: "/sanctum",
  authUrl: "/api",
};

declare global {
  interface Window {
    env: Config;
  }
}

export const IcoApp = {
  config: { ...defaultConfig, ...window?.env }
};
window.env = window?.env || defaultConfig;
IcoApp.config = { ...defaultConfig, ...window.env };

export const apiUrl = () => IcoApp.config.apiUrl;
export const jsonApiUrl = () => IcoApp.config.jsonApiUrl;
export const sanctumUrl = () => IcoApp.config.sanctumUrl;
export const authUrl = () => IcoApp.config.authUrl;

export const BaseApiConfig: RequestOptions = {
  apiVersion: "apiUrl"
};
