import { createSlice } from "../../../utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./types";

export const initialState: UserState = {
  user: undefined,
  isLoading: false,
  hasError: false,
  fetched: false
};

const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    fetchUser(state, action: PayloadAction<null>) {
      state.isLoading = true;
    },
    fetchUserSuccess(state, action: PayloadAction<null>) {
      state.fetched = true;
      state.isLoading = false;
      state.user = action.payload;
    },
    fetchUserError(state, action: PayloadAction<null>) {
      state.isLoading = false;
      state.hasError = true;
      state.fetched = true;
    },
    logoutFetchAction(state) {
      state.isLoading = true;
    },
    logoutSuccessAction(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.user = undefined;
      state.fetched = false;
    },
    logoutErrorAction(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.hasError = true;
      state.fetched = false;
    }
  }
});

/*
 * `reducer` will be used to add this slice to our Redux Store
 * `actions` will be used to trigger change in the state from where ever you want
 * `name` will be used to add this slice to our Redux Store
 */
export const {
  actions: userActions,
  reducer: userReducer,
  name: userSliceKey
} = userSlice;
