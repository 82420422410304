import React from "react";
import "./styles.scss";

const Training = () => {
  return (
    <div className="training">
        <div className="row p-lg-3 my-2 video-width mx-auto mb-4">
            <div className="col-lg-12 px-0 px-0">
                <h2 className="text-center mb-3">Understand CORE X socialswap.io</h2>
                <div className="iframe-container">
                    <iframe
                        className="responsive-iframe"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/x-nFD4KkNFM"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="iframe-container">
                    <iframe
                        className="responsive-iframe"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/EhLkLzhL8WU"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Training;
